import {useEffect, useState} from "react";
import {SendGet} from "../State/Helper";

export default function ActionComponent({ actionId, content, goal, onDelete, onEdit, status, onIrrelevant, onCompleted })
{
    const [action, setAction] = useState();

    async function getAction() {
        
        const response = await SendGet(`api/v1/actions/${actionId}`)
        
        if( response.data !== undefined )
        {
            setAction( response.data );
        }
    }

    useEffect(() => {
        getAction().catch(e => console.log(e));
    }, [actionId]);

    const styles = {
        container: {
            backgroundColor: String(status).toLowerCase() === 'réalisée' ? '#02BC6B' : String(status).toLowerCase() === 'non pertinente' ? '#808080' : '#FFFFFF',
            border: 'solid 1px #000000',
            borderRadius: 20,
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10
        },
        contentContainer: {
            flex: '5',
            display: 'flex',
            flexDirection: 'column',
            padding: 10,
            paddingLeft: 20
        },
        mainTitle: {
            fontSize: 14,
            color: String(status).toLowerCase() === 'réalisée' ? '#FFFFFF' : '#000000',
            fontWeight: 700,
            marginBottom: 5
        },
        secondaryTitle: {
            fontSize: 12,
            color: String(status).toLowerCase() === 'réalisée' ? '#FFFFFF' : 'grey',
            fontWeight: 700
        },
        iconStyle: {
            verticalAlign: 'middle'
        },
        buttonContainer: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row'
        },
        primaryButton: {
            backgroundColor: '#000000',
            border: 'none',
            color: '#FFFFFF',
            fontSize: 12,
            fontWeight: 700,
            borderRadius: 15,
            margin: 10,
            height: 40,
            paddingLeft: 10,
            paddingRight: 10,
            cursor: 'pointer',
            width: 120
        },
        actionButton: {
            border: 'none',
            color: '#FFFFFF',
            fontSize: 12,
            height: 40,
            borderRadius: '50%',
            margin: 10,
            paddingLeft: 10,
            paddingRight: 10,
            cursor: 'pointer'
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.contentContainer}>
                <span style={styles.mainTitle}>{content}</span>
                <span style={styles.secondaryTitle}><span className="material-symbols-outlined" style={styles.iconStyle}>flag_circle</span> {goal}</span>
            </div>
            <div style={styles.buttonContainer}>
                { String(status).toLowerCase() !== 'réalisée' && <button onClick={onCompleted} style={styles.primaryButton}><span className="material-symbols-outlined" style={styles.iconStyle}>done</span> Réalisée</button> }
                { String(status).toLowerCase() !== 'non pertinente' && <button onClick={onIrrelevant} style={{ ...styles.primaryButton, width: 150 }}><span className="material-symbols-outlined" style={styles.iconStyle}>block</span> Non pertinente</button> }
                <button onClick={onEdit} style={{ ...styles.actionButton, backgroundColor: 'green' }}><span className="material-symbols-outlined">edit</span></button>
                <button onClick={onDelete} style={{ ...styles.actionButton, backgroundColor: 'red',  }}><span className="material-symbols-outlined">delete</span></button>
            </div>
        </div>
    );
}
