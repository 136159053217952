import RiskSideMenu from "../Components/RiskSideMenu";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getConnectedUser, SendPost, SendGet, SendPut, isEmpty} from "../State/Helper";
import Success from "../Components/Success";
import Error from "../Components/Error";
import RiskTopMenu from "../Components/RiskTopMenu";

export default function CreateAction() {

    const [isEditMode, setIsEditMode] = useState(false);

    const [creatorId, setCreatorId] = useState("");
    const [riskId, setRiskId] = useState("");
    const [frTitle, setFrTitle] = useState("");
    const [frContent, setFrContent] = useState("");
    const [frGoal, setFrGoal] = useState("");
    const [status, setStatus] = useState("Active");
    const [completionDate, setCompletionDate] = useState("");
    const [frComment, setFrComment] = useState("");
    const [renewal, setRenewal] = useState(0);
    const [risk, setRisk] = useState(null);

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const navigate = useNavigate();
    const connectedUser = getConnectedUser();
    const { idRisk, actionId } = useParams();

    // Check if the page is in create or edit mode
    useEffect(() => {
        async function fetch() {
            if( !isEmpty(actionId) )
            {
                const response = await SendGet(`api/v1/actions/${actionId}`);

                if(response.data)
                {
                    setCreatorId(response.data.creatorId);
                    setRiskId(response.data.riskId);
                    setFrTitle(response.data.frTitle);
                    setFrContent(response.data.frContent);
                    setFrGoal(response.data.frGoal);
                    setStatus(response.data.status);
                    setCompletionDate(response.data.completionDate);
                    setFrComment(response.data.frComment);
                    setRenewal(response.data.renewal);

                    setIsEditMode(true);
                }
            }
        }
        fetch().catch(e => console.log(`Error occurred during getting the action.`));
    }, []);

    function createAction() {

        if(
            isEmpty(frTitle) ||
            isEmpty(frContent) ||
            isEmpty(frGoal) ||
            isEmpty(status) ||
            isEmpty(renewal)
            )
        {
            setError(`Veuillez remplir tous les champs obligatoires!`);
            return;
        }

        SendPost(`api/v1/actions`, {
            creatorId: connectedUser.id,
            riskId: idRisk,
            enTitle: "",
            frTitle: frTitle,
            enContent: "",
            frContent: frContent,
            enGoal: "",
            frGoal: frGoal,
            status: status,
            completionDate: completionDate,
            enComment: "",
            frComment: frComment,
            renewal: renewal
        })
        .then(async () => {

            setCreatorId("");
            setRiskId("");
            setFrTitle("");
            setFrContent("");
            setFrGoal("");
            setStatus("");
            setCompletionDate("");
            setFrComment("");
            setRenewal("");

            navigate(`/risks/detail/${idRisk}`);

        })
        .catch((error) => {

            setError(`Une erreur s'est produite lors de l'action!`);

        });
    }

    function getRisk() {
        SendGet(`api/v1/risks/${idRisk}`)
        .then(response => {
            if( response.data !== undefined )
            {
                setRisk(response.data);
            }
        })
        .catch(e => console.log(e));
    }

    async function updateRisk() {
        await SendPut(`api/v1/risks/${idRisk}`, {
            ...risk,
            reevaluate: 1
        });
    }

    useEffect(() => {
        getRisk();
    }, []);

    function updateAction() {

        if( isEmpty(creatorId) ||
            isEmpty(riskId) ||
            isEmpty(frTitle) ||
            isEmpty(frContent) ||
            isEmpty(frGoal) ||
            isEmpty(status) ||
            isEmpty(renewal)
            )
        {
            setError(`Veuillez remplir tous les champs obligatoires!`);
            return;
        }

        SendPut(`api/v1/actions/${actionId}`, {
            creatorId: connectedUser.id,
            riskId: riskId,
            frTitle: frTitle,
            frContent: frContent,
            frGoal: frGoal,
            status: status,
            completionDate: completionDate,
            frComment: frComment,
            renewal: renewal
        })
        .then(() => {

            setCreatorId("");
            setRiskId("");
            setFrTitle("");
            setFrContent("");
            setFrGoal("");
            setStatus("");
            setCompletionDate("");
            setFrComment("");
            setRenewal("");

            navigate(`/risks/detail/${idRisk}`)
        })
        .catch(() => {
            setError(`Une erreur s'est produite lors de l'action!`);
        });
    }

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#FFFFFF'
        },
        mainSectionContainer: {
            margin: 20,
            marginLeft: 20,
            flex: 1
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        mainButton: {
            color: '#FFFFFF',
            backgroundColor: '#2F87FB',
            fontSize: 13,
            padding: '5px 20px 5px 20px',
            textTransform: 'uppercase',
            borderRadius: 15,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none'
        },
        mainButtonIcon: {
            color: '#FFFFFF',
            verticalAlign: 'middle'
        },
        formContainer: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            margin: 40,
            padding: 20
        },
        inputRow: {
            display: 'flex',
            flexDirection: 'column',
            padding: 10
        },
        inputField: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        selectField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        colLabel: {
            fontSize: 14,
            textTransform: 'uppercase',
            color: '#000000'
        },
        iconStyle: {
            fontSize: 23,
            verticalAlign: 'middle'
        },
        requiredLabel: {
            color: '#D8111C'
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: 700,
            paddingTop: 10,
            paddingBottom: 10,
            height: 35
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            height: 35,
            fontWeight: 700,
            paddingTop: 10,
            paddingBottom: 10,
        }
    }

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div>
                    </div>
                    <div style={styles.formContainer}>

                        <Success isVisible={success != null} sucessMessage={success} handleClose={() => setSuccess(null)} />
                        <Error isVisible={error != null} errorMessage={error} handleClose={() => setError(null)} />

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Titre <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" style={styles.inputField} value={frTitle} onChange={e => setFrTitle(e.target.value)} />
                        </div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Contenu <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" style={styles.inputField} value={frContent} onChange={e => setFrContent(e.target.value)} />
                        </div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Objectif <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" style={styles.inputField} value={frGoal} onChange={e => setFrGoal(e.target.value)} />
                        </div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Statut <span style={styles.requiredLabel}>*</span></span>
                            <select value={status} style={styles.selectField} onChange={e => setStatus(e.target.value)}>
                                <option value="active">Active</option>
                                <option value="réalisée">Réalisée</option>
                                <option value="non pertinente">Non pertinente</option>
                            </select>
                        </div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Completion date</span>
                            <input type="date" style={styles.inputField} value={completionDate} onChange={e => setCompletionDate(e.target.value)} />
                        </div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Commentaire</span>
                            <input type="text" style={styles.inputField} value={frComment} onChange={e => setFrComment(e.target.value)} />
                        </div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Renouvellement <span style={styles.requiredLabel}>*</span></span>
                            <select value={renewal} onChange={e => setRenewal(e.target.value)} style={styles.selectField}>
                                <option value="1">OUI</option>
                                <option value="0">NON</option>
                            </select>
                        </div>

                        <div style={{ textAlign: 'center', margin: 20 }}>
                            <span onClick={() => navigate(`/risks/detail/${idRisk}`)} style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> Fermer</span>
                            { !isEditMode && <span onClick={() => createAction()} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> créer</span> }
                            { isEditMode && <span style={styles.createBtn} onClick={() => updateAction()}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Enregistrer</span> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
