export default function RiskLeveling() {

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column'
        },
        row: {
            display: 'flex',
            flexDirection: 'row'
        },
        column: {
            flex: 2,
            margin: 5,
            borderRadius: 10,
            padding: 10
        },
        labelNumber: {
            fontSize: 14,
            fontWeight: 700,
            backgroundColor: '#FFFFFF',
            padding: 2,
            borderRadius: '50%'
        },
        labelTitle: {
            fontSize: 12,
            fontWeight: 700
        },
        labelPrice: {
            fontSize: 12,
            fontWeight: 700
        }
    }

    return (
        <div style={styles.container}>

            <div style={styles.row}>
                <div style={{...styles.column, backgroundColor: '#FFF1E8', border: 'solid 1px #9E542E'}}>
                    <span style={{...styles.labelNumber, color: '#9E542E'}}>4</span> <br/>
                    <span style={{...styles.labelTitle, color: '#9E542E'}}>Critique</span> <br/>
                    <span style={{...styles.labelPrice, color: '#9E542E'}}>Impact majeur</span>
                </div>
                <div style={{...styles.column, backgroundColor: '#FEFAF0', border: 'solid 1px #8B7435'}}>
                    <span style={{...styles.labelNumber, color: '#8B7435'}}>3</span> <br/>
                    <span style={{...styles.labelTitle, color: '#8B7435'}}>Élevée</span> <br/>
                    <span style={{...styles.labelPrice, color: '#8B7435'}}>Impact significatif</span>
                </div>
                <div style={{...styles.column, backgroundColor: '#F8FFF3', border: 'solid 1px #6CA943'}}>
                    <span style={{...styles.labelNumber, color: '#6CA943'}}>2</span> <br/>
                    <span style={{...styles.labelTitle, color: '#6CA943'}}>Modérée</span> <br/>
                    <span style={{...styles.labelPrice, color: '#6CA943'}}>Impact limité</span>
                </div>
                <div style={{...styles.column, backgroundColor: '#E9F8EF', border: 'solid 1px #72B68C'}}>
                    <span style={{...styles.labelNumber, color: '#72B68C'}}>1</span> <br/>
                    <span style={{...styles.labelTitle, color: '#72B68C'}}>Faible</span> <br/>
                    <span style={{...styles.labelPrice, color: '#72B68C'}}>Impact minimal</span>
                </div>
            </div>

        </div>
    );
}