import RiskSideMenu from "../Components/RiskSideMenu";
import Error from "../Components/Error";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getConnectedUser, isEmpty, SendGet, SendPost, SendPut} from "../State/Helper";
import RiskLeveling from "../Components/RiskLeveling";
import ProbabilityLevel from "../Components/ProbabilityLevel";
import {calculateRisk, calculateRiskBrute} from "../configuration/riskUtils";
import RiskTopMenu from "../Components/RiskTopMenu";
import MaitriseLevel from "../Components/MaitriseLevel";

export default function CreateAssessment() {

    const { riskId, id } = useParams();

    const [isEditMode, setIsEditMode] = useState(false);
    const [version, setVersion] = useState('');
    const [ba, setBa] = useState('');
    const [assessmentDate, setAssessmentDate] = useState('');
    const [irreleventRisk, setIrreleventRisk] = useState('');
    const [occurrence, setOccurrence] = useState(1);
    const [comment, setComment] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [assessments, setAssessments] = useState([]);
    const [risk, setRisk] = useState(null);
    const [showHelp, setShowHelp] = useState(0);

    const [impact, setImpact] = useState(1);
    const [maitrise, setMaitrise] = useState(0);

    const navigate = useNavigate();
    const connectedUser = getConnectedUser();

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        mainSectionContainer: {
            margin: 20,
            marginLeft: 20,
            flex: 1,
            backgroundColor: '#FFFFFF',
            padding: 10
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        mainButton: {
            color: '#FFFFFF',
            backgroundColor: '#2F87FB',
            fontSize: 13,
            padding: '5px 20px 5px 20px',
            textTransform: 'uppercase',
            borderRadius: 15,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none'
        },
        mainButtonIcon: {
            color: '#FFFFFF',
            verticalAlign: 'middle'
        },
        formContainer: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            margin: 40,
            padding: 20
        },
        inputRow: {
            display: 'flex',
            flexDirection: 'column',
            padding: 10
        },
        inputField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        selectField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        colLabel: {
            fontSize: 14,
            textTransform: 'uppercase',
            color: '#000000'
        },
        iconStyle: {
            fontSize: 23,
            verticalAlign: 'middle'
        },
        requiredLabel: {
            color: '#D8111C'
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: 700,
            paddingTop: 10,
            paddingBottom: 10,
            height: 35
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            height: 35,
            fontWeight: 700,
            paddingTop: 10,
            paddingBottom: 10,
        },
        row: {
            display: 'flex',
            flexDirection: 'row'
        },
        column: {
            flex: 2
        }
    }

    // Check if opened in edit mode
    useEffect(() => {
        async function fetch() {
            if( !isEmpty(id) )
            {
                const response = await SendGet(`api/v1/assessments/${id}`);

                if(response.data)
                {
                    setVersion(response.data.version);
                    setBa(response.data.baId);
                    setAssessmentDate(response.data.assesementDate);
                    setIrreleventRisk(response.data.irreleventRisk);
                    setImpact(response.data.impact);
                    setOccurrence(response.data.occurrence);
                    setComment(response.data.frComment);
                    setMaitrise(response.data.maitrise);
                    setIsEditMode(true);
                }
            }
        }
        fetch().catch(e => console.log(`Error occurred during the getting of the Assessment.`));
    }, []);

    async function getAssessments()
    {
        // Get all assessments for that specific risk
        const assessmentsResponse = await SendGet(`api/v1/assessments/risk/${riskId}`);

        if( !isEmpty(assessmentsResponse.data) )
        {
            setAssessments(assessmentsResponse.data);
        }
    }

    // Get the assessments list onload
    useEffect(() => {
        async function fetch() {
            await getRisk();
            await getAssessments();
        }
        fetch();
    }, []);

    // Get risk details
    async function getRisk() {
        const response = await SendGet(`api/v1/risks/${riskId}`);
        if(response.data !== undefined)
        {
            setRisk(response.data);
        }
    }

    // Update risk reevaluate state to 0
    async function updateRisk() {
        await SendPut(`api/v1/risks/${riskId}`, {
            ...risk,
            reevaluate: 0
        });
    }

    // Create new assessment
    const createAssessment = async () => {

        // Check for empty fields
        if ( isEmpty(occurrence) || isEmpty(impact) )
        {
            setError("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        // Find the current active version of the assessment
        let objectWithMaxVersion = 0;

        if( assessments.length > 0 )
        {
            objectWithMaxVersion = assessments.reduce((max, obj) => parseInt(obj.version) > parseInt(max.version) ? obj : max);
        }

        // Calculate the risk values
        const calc = await calculateRisk(impact, maitrise, occurrence);

        const calcRiskBrut = calculateRiskBrute ( impact, occurrence, maitrise );

        // Send the assessment to the backend to be saved
        SendPost(`api/v1/assessments`, {

           version: parseInt(objectWithMaxVersion.version) ? parseInt(objectWithMaxVersion.version) + 1 : parseInt(assessments.length) + 1,
           baId: JSON.parse(localStorage.getItem('businessActivity')).value,
           creatorId: connectedUser.id,
           riskId: riskId,
           assesementDate: Date.now(),
           irreleventRisk: irreleventRisk,
           impact: impact,
           occurrence: occurrence,
           frComment: comment,
           maitrise: maitrise,
           riskBrute: parseFloat(calcRiskBrut).toFixed(2),
           riskNet: parseFloat(calc.riskNet).toFixed(2),
           riskBruteNormalise: parseFloat(calc.riskBruteNormalise).toFixed(2),
           riskNetNormalise: parseFloat(calc.riskNetNormalise).toFixed(2),
           maitrisePercentage: parseFloat(calc.maitrisePercentage).toFixed(2),
           marginProgres: Math.round(calc.marginProgres),
           marginProgresNormalise: Math.round(calc.marginProgresNormalise),
           marginProgresPercentage: parseFloat(calc.marginProgresPercentage).toFixed(2),
           marginProgresPercentageNormalise: parseFloat(calc.marginProgresPercentageNormalise).toFixed(2),
           credate: Date.now()

        })
        .then(async () => {
            await updateRisk();
            navigate(`/risks/detail/${riskId}`);
        })
        .catch(error => {
            setError('Une erreur s\'est produite lors de l\'action.', error);
        });

        // Clean the fields
        setImpact(1);
        setOccurrence(1);
        setComment("");
        setMaitrise(0);
    }

    // Update existing assessment
    const updateAssessment = async () => {

        // Check for empty fields
        if( isEmpty(occurrence) || isEmpty(impact) )
        {
            setError("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        // Check if occurrence exists
        if( occurrence <= 0 )
        {
            setError("La valeur de l'occurrence doit être supérieure à 0.")
            return;
        }

        // Calculate the risk values
        const calc = await calculateRisk(impact, maitrise, occurrence);

        SendPut(`api/v1/assessments/${id}`, {

            version: version,
            baId: ba,
            creatorId: connectedUser.id,
            riskId: riskId,
            assesementDate: assessmentDate,
            irreleventRisk: irreleventRisk,
            impact: impact,
            occurrence: occurrence,
            frComment: comment,
            maitrise: maitrise,
            riskBrute: parseFloat(calc.riskBrute).toFixed(2),
            riskNet: parseFloat(calc.riskNet).toFixed(2),
            riskBruteNormalise: parseFloat(calc.riskBruteNormalise).toFixed(2),
            riskNetNormalise: parseFloat(calc.riskNetNormalise).toFixed(2),
            maitrisePercentage: parseFloat(calc.maitrisePercentage).toFixed(2),
            marginProgres: Math.round(calc.marginProgres),
            marginProgresNormalise: Math.round(calc.marginProgresNormalise),
            marginProgresPercentage: parseFloat(calc.marginProgresPercentage).toFixed(2),
            marginProgresPercentageNormalise: parseFloat(calc.marginProgresPercentageNormalise).toFixed(2)

        })
        .then(() => {
            setSuccess('L\'évaluation a été mise à jour avec succès !');
        })
        .catch(error => {
            setError('Une erreur s\'est produite lors de l\'action.');
        });
    }

    return (
        <div>
            <RiskTopMenu />
            { !isEmpty(error) && <Error>{error}</Error> }
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div>
                    </div>
                    <div style={styles.formContainer}>

                        { id && <span style={{ color: '#024194' }}><input type="checkbox" /> <span className="material-symbols-outlined" style={{ verticalAlign: 'middle' }} >block</span> Irrelevant risk</span> }

                        { showHelp === 1 && <RiskLeveling /> }

                        { showHelp === 2 && <ProbabilityLevel /> }

                        { showHelp === 3 && <MaitriseLevel /> }

                        <div style={{ ...styles.row, marginTop: 20 }}>
                            <div style={{...styles.inputRow, ...styles.column}}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Impact</span>
                                <select style={styles.selectField} onFocus={() => setShowHelp(1)} defaultValue={impact} onChange={e => setImpact(e.target.value)}>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                </select>
                            </div>
                            <div style={{...styles.inputRow, flex: 3}}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Occurrence <span style={styles.requiredLabel}>*</span></span>
                                <select style={styles.selectField} onFocus={() => setShowHelp(2)} defaultValue={occurrence} onChange={e => setOccurrence(e.target.value)}>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                </select>
                            </div>
                            <div style={{...styles.inputRow, flex: 3}}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Maitrise <span style={styles.requiredLabel}>*</span></span>
                                <select style={styles.selectField} onFocus={() => setShowHelp(3)} defaultValue={maitrise} onChange={e => setMaitrise(e.target.value)}>
                                    <option value={0}>0</option>
                                    <option value={30}>30</option>
                                    <option value={60}>60</option>
                                    <option value={90}>90</option>
                                </select>
                            </div>
                        </div>

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Commentaire</span>
                            <textarea style={styles.inputField} value={comment} onChange={e => setComment(e.target.value)}></textarea>
                        </div>

                        <div style={{ textAlign: 'center', margin: 20 }}>
                            <span onClick={() => navigate(`/risks/detail/${riskId}`)} style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> Fermer</span>
                            { !isEditMode && <span onClick={() => createAssessment()} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Créer</span> }
                            { isEditMode && <span style={styles.createBtn} onClick={() => updateAssessment()}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Enregistrer</span> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
