import React, { useEffect, useState } from "react";
import {getColorHexCode, isEmpty, SendGet} from "../State/Helper";
import { useAuth } from "../State/useAuth";

const RiskPopUp = ({ isOpen, handleClose, selectedCircle = null, selectedImpOc = null }) => {

    const [riskList, setRiskList] = useState([]);
    const { cartographie, businessUnit, country, company, businessActivity, searchKeyword } = useAuth();

    async function getRiskList()
    {
        let response = await SendGet(`api/v1/risks/currentuser`);

        if (!isEmpty(response.data))
        {
            let parsedCartographie = cartographie.value !== null ? parseInt(cartographie.value) : null;
            let parsedBusinessUnit = businessUnit.value !== null ? parseInt(businessUnit.value) : null;
            let parsedCountry = country.value !== null ? parseInt(country.value) : null;
            let parsedCompany = company.value !== null ? parseInt(company.value) : null;
            let parsedBusinessActivity = businessActivity.value !== null ? parseInt(businessActivity.value) : null;
            let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

            let risks = response.data.filter(item =>
                (parsedCartographie === null || parseInt(item.mappingId) === parsedCartographie) &&
                (parsedBusinessUnit === null || parseInt(item.buId) === parsedBusinessUnit) &&
                (parsedCountry === null || parseInt(item.countryId) === parsedCountry) &&
                (parsedCompany === null || parseInt(item.companyId) === parsedCompany) &&
                (parsedBusinessActivity === null || parseInt(item.baId) === parsedBusinessActivity) &&
                (parseSearchKeyword === null || item.enTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase())) &&
                (parseSearchKeyword === null || item.frTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase()))
            );

            let assessedRisks = [];
            await Promise.all(risks.map(async risk => {
                assessedRisks.push({ ...risk, calcs: await getAssessment(risk.id) });
            }));

            let evaluatedRisks = assessedRisks.filter(item => !isEmpty(item.calcs));

            if( selectedCircle !== null )
            {
                let filteredRisks = [];

                filteredRisks = evaluatedRisks.filter(risk => {
                    let maitrise = Number(risk.calcs.maitrise);
                    let riskBrute = Number(risk.calcs.riskBrute);
                    let priority = riskBrute <= 160 ? 1 : riskBrute <= 360 ? 2 : 3;
                    return maitrise === Number(selectedCircle.x) && priority === Number(selectedCircle.y);
                });

                setRiskList(filteredRisks);
            }
            else if( selectedImpOc !== null )
            {
                let filteredRisks = [];

                 evaluatedRisks.map(risk => {

                    let occurrence = Number(risk.calcs.occurrence);
                    let impact = Number(risk.calcs.impact);

                    if( occurrence === selectedImpOc.occurrence && impact === selectedImpOc.impact )
                    {
                        filteredRisks.push(risk);
                    }
                });

                setRiskList(filteredRisks);
            }

        } else {
            setRiskList([]);
        }
    }

    async function getAssessment(riskId) {
        let assessments = await SendGet(`api/v1/assessments/risk/${riskId}`);
        return !isEmpty(assessments)
            ? assessments.data.reduce((max, obj) => (parseInt(obj.version) > parseInt(max.version) ? obj : max), assessments.data[0])
            : null;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getRiskList();
            } catch (error) {
                console.error(error);
            }
        };

        fetchData().catch(e => console.log(`Error occurred: `, e));
    }, [selectedCircle, selectedImpOc]);

    return (
        <div>
            {isOpen && (
                <div style={styles.popupOverlay}>
                    <div style={styles.popupContent}>
                        <span style={styles.closeButtonStyle} onClick={handleClose}>&times;</span>
                        <table style={styles.table}>
                            <thead>
                            <tr style={styles.tableHeader}>
                                <th>Titre</th>
                                <th>Impact</th>
                                <th>Occurrence</th>
                                <th>Maitrise</th>
                                <th>Score</th>
                            </tr>
                            </thead>
                            <tbody>
                            {riskList.map(risk => (
                                <tr key={risk.id} style={styles.tableRow}>
                                    <td style={styles.tableCell}>{risk.frTitle}</td>
                                    <td style={styles.tableCell}>{risk.calcs.impact}</td>
                                    <td style={styles.tableCell}>{risk.calcs.occurrence}</td>
                                    <td style={styles.tableCell}>{risk.calcs.maitrise}</td>
                                    <td style={styles.tableCell}><div style={{ backgroundColor: getColorHexCode(risk.calcs.riskBrute), color: "#FFFFFF", padding: 5, textAlign: "center", width: 30, borderRadius: 30 }}>{risk.calcs.riskBrute}</div></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

// Inline styles for the component
const styles = {
    popupOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    popupContent: {
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "10px",
        width: "600px",
        height: "800px",
        overflow: "scroll",
        zIndex: 1001,
        position: "relative",
    },
    closeBtn: {
        position: "absolute",
        top: "10px",
        right: "10px",
        backgroundColor: "red",
        color: "white",
        border: "none",
        padding: "5px 10px",
        borderRadius: "5px",
        cursor: "pointer",
    },
    table: {
        width: "100%",
        marginTop: 30,
        marginBottom: 30,
        borderCollapse: "collapse",
    },
    tableHeader: {
        backgroundColor: "#f2f2f2",
        color: "#333",
        borderBottom: "2px solid #ccc",
    },
    tableRow: {
        borderBottom: "1px solid #ccc",
        transition: "background-color 0.3s",
    },
    tableCell: {
        padding: "10px",  // Add padding for spacing
        fontSize: "14px", // Adjust font size for content
        textAlign: "left",
    },
    closeButtonStyle: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '18px',
        cursor: 'pointer',
    }
};

export default RiskPopUp;