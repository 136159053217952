import React, { useState, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { SendGet } from './Helper';

export const AuthProvider = ({ children }) => {

    const [auth, setAuth] = useState({ accessToken: null, refreshToken: null, user: null });
    const [isLoading, setIsLoading] = useState(true);
    const [activeMenu, setActiveMenu] = useState('Grid');
    const [expandSideMenu, setExpandSideMenu] = useState(null);
    const [tabSideMenu, setTabSideMenu] = useState("user");
    const [cartographie, setCartographie] = useState('');
    const [businessUnit, setBusinessUnit] = useState('');
    const [country, setCountry] = useState('');
    const [company, setCompany] = useState('');
    const [businessActivity, setBusinessActivity] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [rankFilter, setRankFilter] = useState(null);
    const [settings, setSettings] = useState();

    useEffect(() => {

        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const user = localStorage.getItem('user');
        const cartographieFilter = JSON.parse(localStorage.getItem('cartographie'));
        const businessUnitFilter = JSON.parse(localStorage.getItem('businessUnit'));
        const countryFilter = JSON.parse(localStorage.getItem('country'));
        const companyFilter = JSON.parse(localStorage.getItem('company'));
        const businessActivityFilter = JSON.parse(localStorage.getItem('businessActivity'));
        const searchKeywordFilter = JSON.parse(localStorage.getItem('searchKeyword'));

        if (accessToken && refreshToken && user) {
            SendGet(`api/v1/auth/validate-token?token=${accessToken}`)
            .then(response => {
                if (response.data) {
                    // Token is valid
                    setAuth({ accessToken, refreshToken, user: JSON.parse(user) });
                } else {
                    // Token is not valid
                    logout();
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error validating token:", error);
                logout();
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }

        if (cartographieFilter) {
            setCartographie(cartographieFilter);
        }

        if(businessUnitFilter) {
            setBusinessUnit(businessUnitFilter);
        }

        if(countryFilter) {
            setCountry(countryFilter);
        }

        if(companyFilter)
        {
            setCompany(companyFilter)
        }

        if(businessActivityFilter)
        {
            setBusinessActivity(businessActivityFilter)
        }

        if(searchKeywordFilter)
        {
            setSearchKeyword(searchKeywordFilter)
        }

        getSettings();

    }, []);

    async function getSettings() {
        const response = await SendGet(`api/v1/general-settings/1`);
        setSettings(response.data);
    }

    const login = (accessToken, refreshToken, user) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('user', JSON.stringify(user));
        setAuth({ accessToken, refreshToken, user });
    };

    const logout = () => {
        localStorage.clear();
        setAuth({ accessToken: null, refreshToken: null, user: null });
        window.location.href = "/login";
    };

    return (
        <AuthContext.Provider value={{ auth, isLoading, setIsLoading, login, logout, activeMenu, setActiveMenu, cartographie, setCartographie, businessUnit, setBusinessUnit, country, setCountry, company, setCompany, businessActivity, setBusinessActivity, searchKeyword, setSearchKeyword, expandSideMenu, setExpandSideMenu, tabSideMenu, setTabSideMenu, settings, rankFilter, setRankFilter }}>
            {!isLoading ? children : <div>Loading...</div>}
        </AuthContext.Provider>
    );
};
