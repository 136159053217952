import React, { useEffect, useState } from "react";
import { getColorHexCode, isEmpty, SendGet } from "../State/Helper";
import { useAuth } from "../State/useAuth";
import RiskPopUp from "./RiskPopUp";

const Heatmap = () => {

    const [circleGroups, setCircleGroups] = useState({});
    const [riskList, setRiskList] = useState([]);
    const {cartographie, businessUnit, country, company, businessActivity, searchKeyword, rankFilter} = useAuth();
    const [showDetail, setShowDetail] = useState(false);
    const [selectedCircle, setSelectedCircle] = useState(null);

    async function getRiskList() {
        let response = await SendGet(`api/v1/risks/currentuser`);

        if (!isEmpty(response.data)) {
            let parsedCartographie = cartographie.value !== null ? parseInt(cartographie.value) : null;
            let parsedBusinessUnit = businessUnit.value !== null ? parseInt(businessUnit.value) : null;
            let parsedCountry = country.value !== null ? parseInt(country.value) : null;
            let parsedCompany = company.value !== null ? parseInt(company.value) : null;
            let parsedBusinessActivity = businessActivity.value !== null ? parseInt(businessActivity.value) : null;
            let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

            let risks = response.data.filter(item =>
                (parsedCartographie === null || parseInt(item.mappingId) === parsedCartographie) &&
                (parsedBusinessUnit === null || parseInt(item.buId) === parsedBusinessUnit) &&
                (parsedCountry === null || parseInt(item.countryId) === parsedCountry) &&
                (parsedCompany === null || parseInt(item.companyId) === parsedCompany) &&
                (parsedBusinessActivity === null || parseInt(item.baId) === parsedBusinessActivity) &&
                (parseSearchKeyword === null || item.enTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase())) &&
                (parseSearchKeyword === null || item.frTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase()))
            );

            let assessedRisks = [];

            await Promise.all(risks.map(async (risk) => {
                assessedRisks.push({ ...risk, calcs: await getAssessment(risk.id) });
            }));

            let evaluatedRisks = assessedRisks.filter(item => !isEmpty(item.calcs));
            evaluatedRisks = evaluatedRisks.sort((a, b) => b.credate - a.credate);

            let nonEvaluatedRisks = assessedRisks.filter(item => isEmpty(item.calcs));

            // Filter by the risk rank
            if( rankFilter !== null && rankFilter !== "not_evaluated" )
            {
                const filtered = [];

                evaluatedRisks.map(item => {

                    if( Number(rankFilter) === 1 )
                    {
                        if( Number(item.calcs.riskBrute) > 360 )
                        {
                            filtered.push(item);
                        }
                    }
                    else if( Number(rankFilter) === 2 )
                    {
                        if( Number(item.calcs.riskBrute) <= 360 && Number(item.calcs.riskBrute) > 160 )
                        {
                            filtered.push(item);
                        }
                    }
                    else if( Number(rankFilter) === 3 )
                    {
                        if( Number(item.calcs.riskBrute) <= 160 )
                        {
                            filtered.push(item);
                        }
                    }

                });

                setRiskList(filtered);
            }
            else if( rankFilter === null )
            {
                setRiskList(evaluatedRisks);
            }

        } else {
            setRiskList([]);
        }
    }

    async function getAssessment(riskId) {
        let assessments = await SendGet(`api/v1/assessments/risk/${riskId}`);
        if (!isEmpty(assessments)) {
            return assessments.data.reduce((max, obj) => (parseInt(obj.version) > parseInt(max.version) ? obj : max), assessments.data[0]);
        } else {
            return null;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getRiskList();
            } catch (error) {
                console.error(error);
            }
        };
        fetchData().catch(e => console.log(`Error occurred: `, e));
    }, [rankFilter, cartographie, businessUnit, country, company, businessActivity]);

    useEffect(() => {
        const newCircleGroups = {};

        riskList.forEach(risk => {
            const x = Number(risk.calcs.maitrise);
            let y = Number(risk.calcs.riskBrute);

            // Categorize y value into 1, 2, or 3
            if (y <= 160) {
                y = 1;
            } else if (y > 160 && y <= 360) {
                y = 2;
            } else if (y > 360) {
                y = 3;
            }

            const key = `${x}:${y}`;
            if (!newCircleGroups[key]) {
                newCircleGroups[key] = { x, y, count: 1 };
            } else {
                newCircleGroups[key].count += 1;
            }
        });

        setCircleGroups(newCircleGroups);
    }, [riskList]);

    const heatmapStyles = {
        position: "relative",
        width: "800px", // Adjusted width
        height: "450px", // Adjusted height
        display: "grid",
        background: `linear-gradient(to top right, ${getColorHexCode(160)}, ${getColorHexCode(240)}, ${getColorHexCode(420)})`,
        gridTemplateColumns: "repeat(4, 1fr)", // 4 equal columns
        gridTemplateRows: "repeat(3, 1fr)", // 3 equal rows (Y-axis 1 to 3)
    };

    const cellStyles = {
        border: "1px solid black",
        position: "relative",
        width: "100%",
        height: "100%",
    };

    const circleStyles = {
        position: "absolute",
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        backgroundColor: "#4F89F4",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
        cursor: "pointer"
    };

    const axisLabelStyles = {
        fontSize: "16px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const yAxisLabelStyles = {
        position: "absolute",
        left: "-150px", // Adjusted to leave space for the Priority label
        top: "50%",
        transform: "translateY(-50%) rotate(-90deg)", // Rotate it to be vertical
        fontSize: "16px",
        fontWeight: "bold",
    };

    const xAxisLabelStyles = {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "bold",
        marginTop: "20px",
    };

    // X-axis (90, 60, 30, 0)
    const xValues = [90, 60, 30, 0];

    // Y-axis (1 to 3, bottom to top)
    const yValues = [1, 2, 3];

    // Adjust Y-index mapping (1 to 3)
    const getYGridIndex = (yValue) => {
        // Y=1 maps to index 2 (bottom), Y=3 maps to index 0 (top)
        return 2 - (yValue - 1);
    };

    return (
        <div style={{ position: "relative", display: "inline-block" }}>

            <RiskPopUp isOpen={showDetail} handleClose={() => setShowDetail(!showDetail)} selectedCircle={selectedCircle} />

            {/* Y-axis labels from bottom to top (1 to 3) on the left side */}
            <div style={{ position: "absolute", height: "450px", display: "flex", flexDirection: "column", justifyContent: "space-between", top: 0, left: "-50px" }}>
                {yValues.map((yValue, index) => (
                    <div key={index} style={{ ...axisLabelStyles, height: "150px" }}>
                        {yValue}
                    </div>
                ))}
            </div>

            {/* "Priority" Label for Y-axis */}
            <div style={yAxisLabelStyles}>
                Priority
            </div>

            {/* Heatmap container with individual cells */}
            <div style={heatmapStyles}>
                {Array.from({ length: 3 }).map((_, rowIndex) =>
                    Array.from({ length: 4 }).map((_, colIndex) => {

                        const key = `${xValues[colIndex]}:${yValues[getYGridIndex(rowIndex + 1)]}`;
                        const circle = circleGroups[key];

                        return (
                            <div key={`${rowIndex}-${colIndex}`} style={cellStyles}>
                                {circle ? (
                                    <div style={circleStyles} onClick={() => { setShowDetail(true); setSelectedCircle(circle); }}>
                                        { circle.count }
                                    </div>
                                ) : null}
                            </div>
                        );
                    })
                )}
            </div>

            {/* X-axis labels from left to right (90, 60, 30, 0) */}
            <div style={{ display: "flex", justifyContent: "space-between", width: "800px", marginTop: "10px" }}>
                {xValues.map((xValue, index) => (
                    <div key={index} style={{ ...axisLabelStyles, width: "25%" }}>
                        {xValue}
                    </div>
                ))}
            </div>

            {/* Centered "Maitrise" Label */}
            <div style={xAxisLabelStyles}>
                <h3>Maitrise</h3>
            </div>
        </div>
    );
};

export default Heatmap;