import RiskSideMenu from "../Components/RiskSideMenu";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getConnectedUser, SendPost, SendGet, SendPut, isEmpty} from "../State/Helper";
import Success from "../Components/Success";
import Error from "../Components/Error";
import RiskTopMenu from "../Components/RiskTopMenu";

export default function CreateSmtp() {

    const [isEditMode, setIsEditMode] = useState(false);

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const navigate = useNavigate();
    const connectedUser = getConnectedUser();
    const { id } = useParams();

    const [creatorId, setCreatorId] = useState('');
    const [fromName, setFromName] = useState('');
    const [fromEmail, setFromEmail] = useState('');
    const [hostname, setHostname] = useState('');
    const [encryption, setEncryption] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [port, setPort] = useState('');
    const [active, setActive] = useState('');

    useEffect(() => {
        async function fetch() {
            if( !isEmpty(id) )
            {
                const response = await SendGet(`api/v1/mailparams/${id}`);
                let smtp = response.data
                if( smtp )
                {
                    setCreatorId( smtp.creatorId );
                    setFromName( smtp.fromName );
                    setFromEmail( smtp.fromEmail );
                    setHostname( smtp.hostname );
                    setEncryption( smtp.encryption );
                    setUsername( smtp.username );
                    setPassword( smtp.password );
                    setPort( smtp.port );
                    setActive( smtp.active );

                    setIsEditMode(true);
                }
            }
        }
        fetch().catch(e => console.log(`Error occurred during the getting of the SMTP.`))
    }, []);

    const createSmtp = async () => {

        if( fromName === '' || fromEmail === '' || hostname === '' || encryption === '' || username === '' || password === '' || port === '' )
        {
            setError('Veuillez remplir tous les champs obligatoires !');
            return false;
        }

        try
        {
            await SendPost('api/v1/mailparams', {
                'creatorId': connectedUser.id,
                'fromName': fromName,
                'fromEmail': fromEmail,
                'hostname': hostname,
                'encryption': encryption,
                'username': username,
                'password': password,
                'port': port,
                'active': 1
            });

            setError(null);
            setSuccess('SMTP ajouté avec succès !');

            navigate(`/smtps`);
        }
        catch(error)
        {
            setSuccess(null);
            setError(`Une erreur est survenue pendant l'action !`);
        }
    }

    const updateSmtp = async () => {

        if( fromName === '' || fromEmail === '' || hostname === '' || encryption === '' || username === '' || password === '' || port === '' )
        {
            setError('Veuillez remplir tous les champs obligatoires !');
            return false;
        }

        try
        {
            await SendPut(`api/v1/mailparams/${id}`, {
                'creatorId': creatorId,
                'fromName': fromName,
                'fromEmail': fromEmail,
                'hostname': hostname,
                'encryption': encryption,
                'username': username,
                'password': password,
                'port': port,
                'active': active
            });

            setError(null);
            setSuccess('SMTP mis à jour avec succès !');

            navigate(`/smtps`);
        }
        catch(error)
        {
            setSuccess(null);
            setError(`Une erreur est survenue pendant l'action !`);
        }
    }

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        mainSectionContainer: {
            margin: 20,
            marginLeft: 20,
            flex: 1
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        mainButton: {
            color: '#FFFFFF',
            backgroundColor: '#2F87FB',
            fontSize: 13,
            padding: '5px 20px 5px 20px',
            textTransform: 'uppercase',
            borderRadius: 15,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none'
        },
        mainButtonIcon: {
            color: '#FFFFFF',
            verticalAlign: 'middle'
        },
        formContainer: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            margin: 40,
            padding: 20
        },
        inputRow: {
            display: 'flex',
            flexDirection: 'column',
            padding: 10
        },
        inputField: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        selectField: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        colLabel: {
            fontSize: 14,
            textTransform: 'uppercase',
            color: '#000000'
        },
        iconStyle: {
            fontSize: 23,
            verticalAlign: 'middle'
        },
        requiredLabel: {
            color: '#D8111C'
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        }
    }

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div style={styles.formContainer}>

                        <Success isVisible={success != null} sucessMessage={success} handleClose={() => setSuccess(null)} />
                        <Error isVisible={error != null} errorMessage={error} handleClose={() => setError(null)} />

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> From name <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" value={fromName} onChange={e => setFromName(e.target.value)} style={styles.inputField} />
                        </div>

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> From email <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" value={fromEmail} onChange={e => setFromEmail(e.target.value)} style={styles.inputField} />
                        </div>

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Hostname <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" value={hostname} onChange={e => setHostname(e.target.value)} style={styles.inputField} />
                        </div>

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Encryption <span style={styles.requiredLabel}>*</span></span>
                            <select value={encryption} style={styles.selectField} onChange={e => setEncryption(e.target.value)} >
                                <option value="">{""}</option>
                                <option value="TLS">TLS</option>
                                <option value="SSL">SSL</option>
                            </select>
                        </div>

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Port number <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" value={port} onChange={e => setPort(e.target.value)} style={styles.inputField} />
                        </div>

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Username <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" value={username} onChange={e => setUsername(e.target.value)} style={styles.inputField} />
                        </div>

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Password <span style={styles.requiredLabel}>*</span></span>
                            <input type="password" value={password} onChange={e => setPassword(e.target.value)} style={styles.inputField} />
                        </div>

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Status <span style={styles.requiredLabel}>*</span></span>
                            <select value={active} style={styles.selectField} onChange={e => setActive(e.target.value)} >
                                <option value="">{""}</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>

                        <div style={{ textAlign: 'center', margin: 20 }}>
                            <span onClick={() => navigate("/smtps")} style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> fermer</span>
                            { !isEditMode && <span onClick={() => createSmtp()} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> créer</span> }
                            { isEditMode && <span style={styles.createBtn} onClick={() => updateSmtp()}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Enregistrer</span> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
