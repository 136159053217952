import axios from "axios";
import {backend_url} from "../configuration/Statics";

export function getConnectedUser() {
    const user = localStorage.getItem('user');
    return JSON.parse(user);
}

export function getAccessToken() {
    return localStorage.getItem('accessToken');
}

export function getRefreshToken() {
    return localStorage.getItem('refreshToken');
}

export async function SendPost(path, data) {
    try
    {
        const accessToken = getAccessToken();
        return await axios.post(`${backend_url}/${path}`, data, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    }
    catch(error)
    {
        return error;
    }
}

export async function SendPut(path, data) {
    try
    {
        const accessToken = getAccessToken();
        return await axios.put(`${backend_url}/${path}`, data, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    }
    catch(error)
    {
        return error;
    }
}

export async function SendPatch(path, data) {
    try
    {
        const accessToken = getAccessToken();
        return await axios.patch(`${backend_url}/${path}`, data, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    }
    catch(error)
    {
        return error;
    }
}

export async function SendDelete(path) {
    try
    {
        const accessToken = getAccessToken();
        return await axios.delete(`${backend_url}/${path}`,{
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    }
    catch(error)
    {
        return error;
    }
}

export async function SendGet(path) {
    try
    {
        const accessToken = getAccessToken();
        return await axios.get(`${backend_url}/${path}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    }
    catch(error)
    {
        return error;
    }
}

// Get a country by ID
export async function GetCountry(countryId) {
    const response = await SendGet(`api/v1/countries/${countryId}`);
    return response.data;
}

// Get a company by ID
export async function GetCompany(companyId) {
    const response = await SendGet(`api/v1/companies/${companyId}`);
    return response.data;
}

// Get a business unit by ID
export async function GetBusinessUnit(businessUnitId) {
    const response = await SendGet(`api/v1/bus/${businessUnitId}`);
    return response.data;
}

// Get business activity by ID
export async function GetBusinessActivity(businessActivityId) {
    const response = await SendGet(`api/v1/business-activities/${businessActivityId}`);
    return response.data;
}

// Get user by ID
export async function GetUser(userId) {
    const response = await SendGet(`api/v1/users/${userId}`);
    return response.data;
}

export function formatDate(timestamp) {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function getColorHexCode(value) {

    if( value !== null )
    {
        value = Number(value);
    }

    if ( value <= 160 && value !== null ) {
        return '#54B872';
    } else if ( value > 160 && value <= 360 ) {
        return '#CEC340';
    } else if ( value > 360 ) {
        return '#D64C47';
    } else {
        // Optionally handle values outside the specified range
        return '#55657B'; // or throw an error, or return a default color, etc.
    }
}

export function isObject(value) {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
}


export function isEmpty(value) {
    return (
        value === null || // check for null
        value === undefined || // check for undefined
        value === '' || // check for empty string
        (Array.isArray(value) && value.length === 0) || // check for empty array
        (value.constructor === Object && Object.keys(value).length === 0) // check for empty object
    );
}

export function convertDateToString(timestamp) {
    const date = new Date(timestamp);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

export async function validateToken ( accessToken )
{
    try
    {
        const response = await SendGet(`api/v1/auth/validate-token?token=${accessToken}`);

        if( response.data )
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    catch(error)
    {
        return false;
    }
}

export async function checkPermission(authorizationName) {

    const connectedUser = getConnectedUser();
    const response = await SendPost(`api/v1/users/check-permission`, { userId: connectedUser.id, authorizationName: authorizationName});

    return !isEmpty( response.status ) && parseInt( response.status ) == 200;
}

export function isBetweenOneAndSix(value) {
    return value >= 1 && value <= 6;
}
