import graviteImg from "../assets/images/matrice_gravite.jpg";
import priorisationImg from "../assets/images/matrice_priorisation.jpg";
import maitriseImg from "../assets/images/risque_brut_maitrise.jpg";
import {useEffect, useState} from "react";
import RiskTopMenu from "../Components/RiskTopMenu";
import RiskSideMenu from "../Components/RiskSideMenu";
import {getColorHexCode, isEmpty, SendGet} from "../State/Helper";
import OccurrenceImpactShape from "../Components/OccurrenceImpactShape";
import CircleMenu from "../Components/CircleMenu";
import Details from "../Components/Details";
import {useAuth} from "../State/useAuth";
import { primaryColor } from "../configuration/colorPalet";
import NotificationBadge from "../Components/NotificationBadge";
import Heatmap from "../Components/HeatMap";

export default function Graphics() {

    const [showSideMenu, setShowSideMenu] = useState(true);
    const [showMaitrise, setShowMaitrise] = useState(false);
    const [showMatrice, setShowMatrice] = useState(false);
    const [occurrenceImpactData, setOccurrenceImpactData] = useState([]);
    const {cartographie, businessUnit, country, company, businessActivity, searchKeyword} = useAuth();

    async function getOccurrenceImpactData() {

        try
        {
            let data = [];

            let riskResponse = await SendGet(`api/v1/risks/currentuser`);

            let risksList = riskResponse.data;

            let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

            // Filter by cartographie AKA (Mapping)
            if( cartographie.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.mappingId) === parseInt(cartographie.value));
            }

            // Filter by businessUnit
            if( businessUnit.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.buId) === parseInt(businessUnit.value));
            }

            // Filter by country
            if( country.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.countryId) === parseInt(country.value));
            }

            // Filter by company
            if( company.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.companyId) === parseInt(company.value));
            }

            // Filter by BusinessActivity
            if( businessActivity.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.baId) === parseInt(businessActivity.value));
            }

            // Filter by search keyword
            if( searchKeyword.value !== null )
            {
                risksList = risksList.filter(risk => ( risk.enTitle.includes(parseSearchKeyword) || risk.frTitle.includes(parseSearchKeyword )));
            }

            await Promise.all(risksList.map(async (risk) => {

                const assessments = await SendGet(`api/v1/assessments/risk/${risk.id}`);

                if ( !isEmpty(assessments.data) )
                {
                    const assessment = assessments.data.reduce((max, obj) => parseInt(obj.version) > parseInt(max.version) ? obj : max);

                    if( !isEmpty(assessment) )
                    {
                        data.push({
                            occurrence: Math.round(assessment.occurrence),
                            impact: Math.round(parseInt(assessment.impact))
                        });
                    }
                }

            }));

            setOccurrenceImpactData(data);

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        async function fetch() {
            await getOccurrenceImpactData();
        }
        fetch().catch(e => console.log(e));
    }, [cartographie, businessUnit, country, company, businessActivity, searchKeyword]);

    const styles = {
        container: {
            display: 'flex',
            flex: 6,
            flexDirection: 'column',
            padding: 20,
            backgroundColor: '#FFFFFF'
        },
        sectionContainer: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            cursor: 'pointer'
        },
        sectionTitle: {
            fontSize: 16,
            textTransform: 'uppercase',
            fontWeight: 700,
            marginTop: 35,
            color: primaryColor,
            flex: 2
        },
        sectionImage: {
            height: 50,
            width: 80,
            padding: 20
        },
        sectionIcon: {
            color: primaryColor,
            fontSize: 30,
            fontWeight: 700,
            verticalAlign: 'middle',
            marginTop: 30,
            marginRight: 10
        },
        mainContainer: {
            backgroundColor: '#FFFFFF',
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer'
        },
        chartContainer: {
            borderTop: 'none',
            borderRight: 'none',
            background: `linear-gradient(to top right, ${ getColorHexCode(1) }, ${ getColorHexCode(2) }, ${ getColorHexCode(3) }, ${ getColorHexCode(4) })`,
            width: `900px`,
            height: `600px`,
            border: '1px solid black',
            position: 'relative',
            borderLeft: 'solid 5px #000000',
            marginTop: 50
        },
        chartRow: {
            height: 50,
            display: 'flex',
            flexDirection: 'row',
            flex: 0.8,
        },
        chartColumn: {
            flex: 0.8,
            border: 'solid 0.2px #C9A457'
        },
        verticalNumbering: {
            flex: 0.8,
            color: '#000000',
            fontWeight: 600
        },
        horizontalNumbering: {
        },
        chartCircle: {
            backgroundColor: '#FFFFFF', width: 80, height: 80, borderRadius: '50%', zIndex: 9999
        }
    }

    return (

        <>
            <RiskTopMenu setShowSideMenu={() => setShowSideMenu(!showSideMenu)} showSideMenu={showSideMenu} />
            <div style={{ display: 'flex' }}>
                <RiskSideMenu showSideMenu={showSideMenu} selectedTab="graphic"  />
                <div style={styles.container}>

                    <NotificationBadge />

                    <div style={styles.sectionContainer} onClick={() => setShowMaitrise(!showMaitrise)}>
                        <img style={styles.sectionImage} alt="image" src={maitriseImg} />
                        <p style={styles.sectionTitle}>RISQUE BRUT & MAITRISE</p>
                        { showMaitrise === false && <span className="material-symbols-outlined" style={styles.sectionIcon}>navigate_next</span> }
                        { showMaitrise === true && <span className="material-symbols-outlined" style={styles.sectionIcon}>expand_more</span> }
                    </div>

                    { showMaitrise &&

                        <div style={{ margin: '0px auto', marginTop: 40 }}>
                            <Heatmap />
                        </div>
                    }

                    <div style={{ ...styles.sectionContainer, marginTop: 20 }} onClick={() => setShowMatrice(!showMatrice)}>
                        <img style={styles.sectionImage} alt="image" src={graviteImg} />
                        <p style={styles.sectionTitle}>Matrice de gravité (occurrence & impact)</p>
                        { showMatrice === false && <span className="material-symbols-outlined" style={styles.sectionIcon}>navigate_next</span> }
                        { showMatrice === true && <span className="material-symbols-outlined" style={styles.sectionIcon}>expand_more</span> }
                    </div>

                    { showMatrice && <OccurrenceImpactShape data={occurrenceImpactData} /> }

                    </div>

                <CircleMenu  />

            </div>
        </>
    );
}
