import RiskSideMenu from "../Components/RiskSideMenu";
import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Error from "../Components/Error";
import Success from "../Components/Success";
import {getConnectedUser, isEmpty, SendDelete, SendGet, SendPost, SendPut} from "../State/Helper";
import RiskTopMenu from "../Components/RiskTopMenu";

export default function UserCreate() {

    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const { id } = useParams();
    const [isEditMode, setIsEditMode] = useState(false);
    const navigate = useNavigate();

    const [company, setCompany] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [language, setLanguage] = useState("");
    const [status, setStatus] = useState("");
    const [password, setPassword] = useState("");
    const [creatorId, setCreatorId] = useState("");
    const [droits, setDroits] = useState([]);
    const [mappings, setMappings] = useState([]);
    const [bus, setBus] = useState([]);
    const [countries, setCountries] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [bas, setBas] = useState([]);
    const [userAuths, setUserAuths] = useState([]);

    const [pDroit, setPdroit] = useState("");
    const [pMapping, setPmapping] = useState("");
    const [pBu, setpBu] = useState("");
    const [pCountry, setPcountry] = useState("");
    const [pCompany, setPcompany] = useState("");
    const [pBa, setPba] = useState("");
    const [showDroit, setShowDroit] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const connectedUser = getConnectedUser();

    async function updateCheck() {

        if( id )
        {
            try
            {
                const response = await SendGet(`api/v1/users/${id}`);

                if(response.data !== undefined)
                {
                    setCompany(response.data.company ? response.data.company.id : "" );
                    setFirstName(response.data.firstName ?? "");
                    setLastName(response.data.lastName ?? "");
                    setEmail(response.data.email ?? "");
                    setLanguage(response.data.language ?? "");
                    setStatus(response.data.status ?? "");
                    setCreatorId(response.data.creatorId ?? "");

                    setIsEditMode(true);
                }
            }
            catch(error)
            {
                console.log(error);
            }
        }
    }

    async function getDroits() {
        const response = await SendGet(`api/v1/authorizations`);
        if( response.data !== undefined && Array.isArray(response.data) )
        {
            setDroits(response.data);
        }
        else
        {
            setDroits([]);
        }
    }

    async function getMappings() {
        const response = await SendGet(`api/v1/mappings/all`);
        if( !isEmpty(response.data) )
        {
            setMappings(response.data);
        }
        else
        {
            setMappings([]);
        }
    }

    async function getBus() {
        const response = await SendGet(`api/v1/bus/all`);
        if( !isEmpty(response.data) )
        {
            setBus(response.data);
        }
        else
        {
            setBus([]);
        }
    }

    async function getCountries() {
        const response = await SendGet(`api/v1/countries/all`);
        if( !isEmpty(response.data) )
        {
            setCountries(response.data);
        }
        else
        {
            setCountries([]);
        }
    }

    async function getBas() {
        const response = await SendGet(`api/v1/business-activities/all`);
        if( !isEmpty(response.data) )
        {
            setBas(response.data);
        }
        else
        {
            setBas([]);
        }
    }

    async function getCompanies() {
        const response = await SendGet(`api/v1/companies/all`);
        if( !isEmpty(response.data) )
        {
            setCompanies(response.data);
        }
        else
        {
            setCompanies([]);
        }
    }

    async function createUserAuth() {

        if( pMapping === "" || pBu === "" || pCountry === "" || pCompany === "" )
        {
            setError("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        const response = await SendPost(`api/v1/userauths`, {
            id: null,
            authorizationId: pDroit,
            creatorId: connectedUser.id,
            dimBa: pBa,
            dimBu: pBu,
            dimComp: pCompany,
            dimCountry: pCountry,
            dimMap: pMapping,
            userId: id
        });

        if( response.status !== undefined && response.status === 200 )
        {
            setPdroit("");
            setPmapping("");
            setpBu("");
            setPba("");
            setPcompany("");
            setPcountry("");

            getUserAuths();

            setSuccess(`Le droit a été crée avec success.`);

            await getUserAuths();

        }
        else
        {
            setError(`Problème de création du droit!`);
        }

    }

    async function getUserAuths() {

        const response = await SendGet(`api/v1/userauths/user/${id}`);

        if( !isEmpty(response.data) )
        {
            setUserAuths(response.data);
        }
        else
        {
            setUserAuths([]);
        }
    }

    // Check if the page is in create or edit mode
    useEffect(() => {
        updateCheck().catch(e => console.log(`Error: `, e));
    }, []);

    useEffect(() =>{
        async function fetch() {
            await getMappings();
            await getBus();
            await getCompanies();
            await getCountries();
            await getBas();
            await getDroits();
            await getUserAuths();
        }
        fetch().catch(e => console.log(`Error: `, e));
    }, []);

    const createUser = async () =>
    {
        if( company === "" || firstName === "" || lastName === "" || email === "" || language === "" || status === "" || password === "" )
        {
            setError("Veuillez remplir tous les champs obligatoires.");
        }
        else
        {
            if( password.length < 8 )
            {
                setError("Password must be bigger than 8 characters!")
            }
            else
            {
                if( error === null )
                {
                    try
                    {
                        await SendPost('api/v1/auth/register', {
                            "company":  company,
                            "firstName": firstName,
                            "lastName": lastName,
                            "email": email,
                            "password": password,
                            "language": language,
                            "status": status,
                            "creatorId": 1
                        });

                        setSuccess(`Utilisateur créé avec succès !`);
                        navigate(`/users`);
                    }
                    catch(error)
                    {
                        setError(`Une erreur s'est produite pendant l'action.`);
                    }
                }
            }
        }
    }

    async function deleteDroit(userAuthId) {

        if( !window.confirm(`Voulez-vous supprimer l'autorisation pour cet utilisateur ?`) )
        {
           return;
        }

        await SendDelete(`api/v1/userauths/${userAuthId}`);
        await getUserAuths();
    }

    const updateUser = async () => {

        if( company === "" || firstName === "" || lastName === "" || email === "" || language === "" || status === "" )
        {
            setError("Please fill all the required fields!");
        }
        else
        {
            if( changePassword && password === "" )
            {
                setError("Password must be bigger than 8 characters!")
            }

            if(error === null)
            {
                try
                {
                    await SendPut(`api/v1/users/${id}`, {
                        "company":  company,
                        "firstName": firstName,
                        "lastName": lastName,
                        "email": email,
                        "language": language,
                        "status": status,
                        "creatorId": creatorId
                    });

                    setSuccess('User has been updated successfully.');
                    navigate(`/users`);
                }
                catch(error)
                {
                    setError('Error occurred during the action!');
                }
            }
        }
    }

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row'
        },
        sideMenuContainer: {
        },
        contentContainer: {
            flex: 5,
            padding: 20
        },
        formContainer: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            padding: 20
        },
        formRow: {
            display: 'flex',
            flexDirection: 'row'
        },
        formCol: {
            display: 'flex',
            flexDirection: 'column',
            flex: 3,
            padding: 20
        },
        requiredLabel: {
            color: '#D8111C',
            fontSize: 12,
            fontWeight: 700
        },
        inputField: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        selectField: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        colLabel: {
            fontSize: 14,
            textTransform: 'uppercase',
            color: '#000000'
        },
        iconStyle: {
            fontSize: 16,
            verticalAlign: 'middle'
        },
        mainLabel: {
            fontSize: 32,
            color: '#67D0FA'
        },
        seperator: {
            height: 1,
            backgroundColor: '#D1E5FE',
            marginTop: 20,
            marginBottom: 20
        },
        activeBtnGreen: {
            backgroundColor: '#1DBB6E',
            color: '#FFFFFF',
            fontSize: 12,
            textAlign: 'center',
            borderRadius: 20,
            border: 'none',
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10,
            textTransform: 'uppercase',
            marginRight: 10,
            cursor: 'pointer'
        },
        inactiveBtnGreen: {
            backgroundColor: '#FFFFFF',
            color: '#1DBB6E',
            fontSize: 12,
            textAlign: 'center',
            borderRadius: 20,
            border: 'solid 1px #1DBB6E',
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10,
            textTransform: 'uppercase',
            cursor: 'pointer',
            marginRight: 10
        },
        profilContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        inactiveProfilCol: {
            flex: 1.5,
            textAlign: 'center',
            margin: 20,
            border: 'solid 1px #275B9F',
            borderRadius: 10,
            backgroundColor: '#FFFFFF',
            color: '#275B9F',
            padding: 20,
            cursor: 'pointer'
        },
        activeProfilCol: {
            flex: 1.5,
            textAlign: 'center',
            margin: 20,
            border: 'none',
            borderRadius: 10,
            backgroundColor: '#275B9F',
            color: '#FFFFFF',
            padding: 20,
            cursor: 'pointer'
        },
        profilIcon: {
            fontSize: 25,
            marginRight: 5,
            verticalAlign: 'middle'
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 32
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        }
    }

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.container}>
                <div style={styles.sideMenuContainer}>
                    <RiskSideMenu showSideMenu={true} selectedTab="" />
                </div>
                <div style={styles.contentContainer}>

                    <Success isVisible={success != null} sucessMessage={success} handleClose={() => setSuccess(null)} />
                    <Error isVisible={error != null} errorMessage={error} handleClose={() => setError(null)} />

                    <div style={styles.formContainer}>
                        <div style={styles.formRow}>
                            <p style={{ ...styles.requiredLabel, marginLeft: 20 }}>* Champs obligatoires</p>
                        </div>
                        <div style={styles.formRow}>
                            <div style={styles.formCol}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>house</span> socitété <span style={styles.requiredLabel}>*</span></span>
                                <select value={company} style={styles.selectField} onChange={e => setCompany(e.target.value)}>
                                    <option value="" />
                                    {
                                        !isEmpty(companies) && companies.map(item => {
                                            return (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div style={styles.formCol}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>person</span> prénom <span style={styles.requiredLabel}>*</span></span>
                                <input value={firstName} type="text" style={styles.inputField} onChange={e => setFirstName(e.target.value)} />
                            </div>
                        </div>

                        <div style={styles.formRow}>
                            <div style={styles.formCol}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>person</span> nom <span style={styles.requiredLabel}>*</span></span>
                                <input value={lastName} type="text" style={styles.inputField} onChange={e => setLastName(e.target.value)} />
                            </div>
                            <div style={styles.formCol}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>mail</span> email <span style={styles.requiredLabel}>*</span></span>
                                <input value={email} type="email" style={styles.inputField} onChange={e => setEmail(e.target.value)} />
                            </div>
                        </div>

                        <div style={styles.formRow}>
                            <div style={styles.formCol}>
                                <span style={styles.colLabel}> { isEditMode && <input type="checkbox" value={changePassword} onChange={e => setChangePassword(e.target.checked)} /> } <span className="material-symbols-outlined" style={styles.iconStyle}>lock</span> mot de passe { isEditMode && <span style={{ ...styles.requiredLabel, textTransform: 'lowercase' }}>( si vous souhaitez changer le mot de passe )</span> } </span>
                                <input value={password} type="password" style={styles.inputField} onChange={e => setPassword(e.target.value)} />
                            </div>
                            <div style={styles.formCol}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>flag</span> langue</span>
                                <select value={language} style={styles.selectField} onChange={e => setLanguage(e.target.value)}>
                                    <option value="" />
                                    <option value="Français">Français</option>
                                    <option value="Anglais">Anglais</option>
                                </select>
                            </div>
                        </div>

                        <div style={styles.formRow}>
                            <div style={styles.formCol}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>how_to_reg</span> statut</span>
                                <select value={status} style={styles.selectField} onChange={e => setStatus(e.target.value)}>
                                    <option value="" />
                                    <option value="En attente de validation">En attente de validation</option>
                                    <option value="Valide">Valide</option>
                                    <option value="Désactivé">Désactivé</option>
                                    <option value="Mot de passe temporaire">Mot de passe temporaire</option>
                                </select>
                            </div>
                            <div style={styles.formCol}>
                            </div>
                        </div>

                        {
                            isEditMode &&
                            <div>

                                <div style={styles.seperator} />

                                <div style={styles.formRow}>
                                    <div style={styles.formCol}>
                                        <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>manage_accounts</span> Droit de l'utilisateur <span style={styles.requiredLabel}>*</span></span>
                                    </div>
                                </div>

                                <div>
                                    <div style={styles.formRow}>
                                        <div style={styles.formCol}>
                                            <span style={styles.colLabel}><span>1</span> Sélectionnez un droit :</span>
                                            <select style={styles.selectField} value={pDroit} onChange={e => setPdroit(e.target.value)}>
                                                <option value="" />
                                                {
                                                    !isEmpty(droits) && droits.map(item => {
                                                        return (
                                                            <option key={item.id} value={item.id}>{item.authorizationName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div style={styles.formCol}>
                                            <span style={styles.colLabel}><span>2</span> Sélectionnez une cartographie :</span>
                                            <select style={styles.selectField} value={pMapping} onChange={e => setPmapping(e.target.value)}>
                                                <option value="" />
                                                {
                                                    !isEmpty(mappings) && mappings.map(item => {
                                                        return (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div style={styles.formRow}>
                                        <div style={styles.formCol}>
                                            <span style={styles.colLabel}><span>3</span> Sélectionnez BU :</span>
                                            <select style={styles.selectField} value={pBu} onChange={e => setpBu(e.target.value)} >
                                                <option value="" />
                                                <option value="*">Tout</option>
                                                {
                                                    !isEmpty(bus) && bus.map(item => {
                                                        return (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div style={styles.formCol}>
                                            <span style={styles.colLabel}><span>4</span> Sélectionnez un pays :</span>
                                            <select style={styles.selectField} value={pCountry} onChange={e => setPcountry(e.target.value)}>
                                                <option value="" />
                                                <option value="*">Tout</option>
                                                {
                                                    !isEmpty(countries) && countries.map(item => {
                                                        return (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div style={styles.formRow}>
                                        <div style={styles.formCol}>
                                            <span style={styles.colLabel}><span>5</span> Sélectionnez une société :</span>
                                            <select style={styles.selectField} value={pCompany} onChange={e => setPcompany(e.target.value)} >
                                                <option value="" />
                                                <option value="*">Tout</option>
                                                {
                                                    !isEmpty(companies) && companies.map(item => {
                                                        return (
                                                            <option value={item.id} key={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div style={styles.formCol}>
                                            <span style={styles.colLabel}><span>5</span> Sélectionnez business activity :</span>
                                            <select style={styles.selectField} value={pBa} onChange={e => setPba(e.target.value)}>
                                                <option value="" />
                                                <option value="*">Tout</option>
                                                {
                                                    !isEmpty(bas) && bas.map(item => {
                                                        return (
                                                            <option value={item.id} key={item.id}>{item.description}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div style={{ textAlign: 'center' }}>
                                    <button onClick={createUserAuth} style={{ textTransform: 'uppercase', padding: 10, cursor: 'pointer', color: '#000000', backgroundColor: '#FFFFFF', borderRadius: 5, border: 'solid 1px #000000', margin: '0 auto', fontSize: 12 }}><span className="material-symbols-outlined" style={styles.iconStyle}>add</span> ajouter ce profil à l'utilisateur</button>
                                    <button style={{ backgroundColor: '#FFFFFF', color: '#000000', textTransform: 'uppercase', borderRadius: 5, border: 'solid 1px #000000', padding: 10, marginLeft: 10, cursor: 'pointer' }} onClick={() => setShowDroit(!showDroit)}><span className="material-symbols-outlined" style={{ ...styles.iconStyle, marginRight: 5 }}>menu</span>Afficher les permissions</button>
                                </div>

                                <div style={styles.seperator} />

                            </div>
                        }
                        {   showDroit &&
                            <div>
                                <table width="100%" style={{ padding: 10 }}>
                                    <thead>
                                    <tr>
                                        <th style={{ padding: 10, paddingLeft: 0, color: '#000000' }} align="left">Mapping</th>
                                        <th style={{ padding: 10, paddingLeft: 0, color: '#000000' }} align="left">BU</th>
                                        <th style={{ padding: 10, paddingLeft: 0, color: '#000000' }} align="left">Société</th>
                                        <th style={{ padding: 10, paddingLeft: 0, color: '#000000' }} align="left">Pays</th>
                                        <th style={{ padding: 10, paddingLeft: 0, color: '#000000' }} align="left">BA</th>
                                        <th style={{ padding: 10, paddingLeft: 0, color: '#000000' }} align="left">Authorization</th>
                                        <th style={{ padding: 10, paddingLeft: 0, color: '#000000' }} align="left">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        userAuths.map(item => {

                                            let authorization = droits.find(droit => parseInt(droit.id) === parseInt(item.authorizationId));
                                            let mapping = mappings.find(mapping => parseInt(mapping.id) === parseInt(item.dimMap));
                                            let bu = bus.find(bu => parseInt(bu.id) === parseInt(item.dimBu));
                                            let country = countries.find(country => parseInt(country.id) === parseInt(item.dimCountry));
                                            let company = companies.find(company => parseInt(company.id) === parseInt(item.dimComp));
                                            let ba = bas.find(ba => parseInt(ba.id) === parseInt(item.dimBa));

                                            return (
                                                <tr key={item.id}>
                                                    <td style={{ color: '#000000', fontSize: 14 }}>{mapping ? mapping.name : 'N/A'}</td>
                                                    <td style={{ color: '#000000', fontSize: 14 }}>{bu ? bu.name : 'N/A'}</td>
                                                    <td style={{ color: '#000000', fontSize: 14 }}>{country ? country.name : 'N/A'}</td>
                                                    <td style={{ color: '#000000', fontSize: 14 }}>{company ? company.name : 'N/A'}</td>
                                                    <td style={{ color: '#000000', fontSize: 14 }}>{ba ? ba.description : 'N/A'}</td>
                                                    <td style={{ color: '#000000', fontSize: 14 }}>{authorization ? authorization.authorizationName : 'N/A'}</td>
                                                    <td style={{ color: '#000000', fontSize: 14 }}><span className="material-symbols-outlined" style={{ color: 'red', fontSize: 23, cursor: 'pointer' }} onClick={() => deleteDroit(item.id)}>delete</span></td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>

                            </div>
                        }

                        <div style={styles.seperator} />

                        <div style={{ textAlign: 'center' }}>
                            <span onClick={() => navigate('/users')} style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> fermer</span>
                            { !isEditMode && <button onClick={createUser} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> créer</button> }
                            { isEditMode && <button onClick={updateUser} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Enregistrer</button> }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
