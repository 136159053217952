import { SendGet } from "../State/Helper";

export const calculateRisk = async ( impact, maitrise, occurrence ) => {

    // Convert to integers
    impact = Number(impact);
    maitrise = Number(maitrise);
    occurrence = Number(occurrence);

    // Get the active formula
    let rsp = await SendGet(`api/v1/riskassessment`);

    // Check in case of no formula found
    if( rsp.data === undefined || !Array.isArray(rsp.data) )
    {
        return 0;
    }

    // Get the active formula based with the biggest id number which means the formula with higher id number is the one used
    let formula = rsp.data.reduce( (max, obj) => parseInt(obj.id) > parseInt(max.id) ? obj : max );

    // Calculate maitrise percentage
    let maitrisePercentage = Number( parseFloat((maitrise / 4) * 100) );

    // The dynamic part of the formula on scale 4
    let riskBrute = eval(formula.grossRiskFormulaBase6.replace(/:OC/g, Number( parseFloat(occurrence).toFixed(2) )).replace(/:IM/g, Number( parseFloat(impact).toFixed(2) ))); // RiskBrut = Occurrence * Impact
    let riskBruteNormalise = Number(( riskBrute / 16 ) * 4);

    let riskNet = eval(formula.netRiskFormulaBase6.replace(/:SB/g, Number( parseFloat(riskBruteNormalise).toFixed(2) )).replace(/:MP/g, Number( parseFloat(maitrise).toFixed(2) ))); // RiskNet = RiskBrut - maitrise
    let riskNetNormalise = Number( riskNet );

    let marginProgres = Number( riskBruteNormalise - riskNetNormalise );
    let marginProgresNormalise = Number( riskBruteNormalise - riskNetNormalise );
    let marginProgresPercentage = Number( ( marginProgres / 4 ) * 100 );
    let marginProgresPercentageNormalise = Number( ( marginProgres / 4 ) * 100 );

    return {
        riskBrute,
        riskNet,
        riskBruteNormalise,
        riskNetNormalise,
        impact,
        maitrise,
        maitrisePercentage,
        marginProgres,
        marginProgresNormalise,
        marginProgresPercentage,
        marginProgresPercentageNormalise
    };
};

export const calculateRiskBrute = ( impact, occurrence, maitrise ) => {

    return Number(impact) * Number(occurrence) * ( 100 - Number(maitrise) );
}