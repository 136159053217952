import RiskTopMenu from "../Components/RiskTopMenu";
import RiskSideMenu from "../Components/RiskSideMenu";
import {useState, useEffect} from "react";
import EvaluationCard from "../Components/EvaluationCard";
import {
    SendGet,
    formatDate,
    SendPost,
    getConnectedUser,
    getColorHexCode,
    SendDelete,
    SendPut,
    checkPermission,
    isEmpty,
    convertDateToString,
    isObject
} from "../State/Helper";
import {useNavigate, useParams} from "react-router-dom";
import Error from "../Components/Error";
import ActionComponent from "../Components/ActionComponent";
import {useAuth} from "../State/useAuth";
import CommentsComponent from "../Components/CommentsComponent";
import ProgressBar from "@ramonak/react-progress-bar";
import '../assets/css/style.css';
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";

export default function RiskDetail() {

    const {id} = useParams();
    const navigate = useNavigate();
    const connectedUser = getConnectedUser();
    const [showSideMenu, setShowSideMenu] = useState(true);
    const [activeForm, setActiveForm] = useState("general");
    const [showScenario, setShowScenario] = useState(false);
    const [showResource, setShowResource] = useState(false);
    const [showLien, setShowLien] = useState(false);
    const [showTravaux, setShowTravaux] = useState(false);
    const [risk, setRisk] = useState("");
    const [assessments, setAssessments] = useState([]);
    const [riskOwner, setRiskOwner] = useState("");
    const [theme, setTheme] = useState("");
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [resourceLabel, setResourceLabel] = useState("");
    const [resourceSource, setResourceSource] = useState("");
    const [resourceLanguage, setResourceLanguage] = useState("FR");
    const [scenarioLabel, setScenarioLabel] = useState("");
    const [scenarioFile, setScenarioFile] = useState("");
    const [taskLabel, setTaskLabel] = useState("");
    const [taskUser, setTaskUser] = useState("");
    const [taskDate, setTaskDate] = useState("");
    const [linkLabel, setLinkLabel] = useState("");
    const [linkUrl, setLinkUrl] = useState("");
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [activeAssessment, setActiveAssessment] = useState();
    const [actionsList, setActionsList] = useState(null);
    const [scenarioList, setScenarioList] = useState([]);
    const [resourcesList, setResourcesList] = useState(null);
    const [linksList, setLinksList] = useState(null);
    const [tasksList, setTasksList] = useState(null);
    const [mapping, setMapping] = useState(null);
    const [hasEvaluate, setHasEvaluate] = useState(false);
    const [hasCreate, setHasCreate] = useState(false);
    const [hasEdit, setHasEdit] = useState(false);
    const [hasDelete, setHasDelete] = useState(false);
    const [hasPrint, setHasPrint] = useState(false);
    const { cartographie } = useAuth();

    async function getPermissions() {

        const createRole = await checkPermission('CREATE');
        const evaluateRole = await checkPermission('EVALUATE');
        const editRole = await checkPermission('EDIT');
        const deleteRole = await checkPermission('DELETE');
        const printRole = await checkPermission('PRINT');

        setHasCreate(createRole);
        setHasEvaluate(evaluateRole);
        setHasEdit(editRole);
        setHasDelete(deleteRole);
        setHasPrint(printRole);
    }

    useEffect(() => {

        // Get all permissions first
        getPermissions();

    }, []);

    // Get all the required data
    useEffect(() => {

        async function fetch()
        {
            const riskResponse = await SendGet(`api/v1/risks/${id}`);

            if( !isEmpty(riskResponse) )
            {
                setRisk(riskResponse.data);
            }
            else
            {
                setRisk(null);
            }

            const assessmentResponse = await SendGet(`api/v1/assessments/risk/${id}`);

            if( !isEmpty(assessmentResponse.data) )
            {
                let assessments = assessmentResponse.data.sort((a, b) => parseInt(b.version) - parseInt(a.version));
                setAssessments(assessments);
            }
            else
            {
                setAssessments([]);
            }

            if( !isEmpty(assessmentResponse.data) )
            {
                let objectWithMaxVersion = 0;

                if( assessmentResponse.data.length > 0 )
                {
                    objectWithMaxVersion = assessmentResponse.data.reduce((max, obj) => parseInt(obj.version) > parseInt(max.version) ? obj : max);
                }

                console.log('+ RiskNetNormalise : ', objectWithMaxVersion.riskNetNormalise);

                setActiveAssessment(objectWithMaxVersion);
            }

            const userResponse = await SendGet(`api/v1/users/${riskResponse.data.creatorId}`);
            if( !isEmpty(userResponse.data) )
            {
                setRiskOwner(userResponse.data);
            }

            const themeResponse = await SendGet(`api/v1/themes/${riskResponse.data.themeId}`);
            if(!isEmpty(themeResponse.data))
            {
                setTheme(themeResponse.data);
            }

            const usersResponse = await SendGet(`api/v1/users`);
            if(!isEmpty(userResponse.data))
            {
                setUsers(usersResponse.data);
            }
            else
            {
                setUsers([]);
            }

            const actionResponse = await SendGet(`api/v1/actions/risk/${id}`) ?? null;
            if(!isEmpty(actionResponse.data))
            {
                setActionsList(actionResponse.data);
            }
            else
            {
                setActionsList([]);
            }

            setIsDataLoaded(true);
        }

        fetch().catch(e => console.log(`Error occurred during getting the risk data.`));

    }, []);

    const createResource = async () => {

        try
        {
            if( resourceLabel === '' || resourceSource === '' || resourceLanguage === '' )
            {
                setError("Veuillez remplir tous les champs requis!");
            }
            else
            {
                await SendPost(`api/v1/resources`, {
                    'creatorId': connectedUser.id,
                    'riskId': id,
                    'label': resourceLabel,
                    'source': resourceSource,
                    'language': resourceLanguage
                });

                setResourceLabel('');
                setResourceSource('');
                setResourceLanguage('');

                await getResources().finally(() => setIsDataLoaded(true));
            }

        }
        catch(Exception)
        {
            setError("Une erreur s'est produite pendant l'action.");
        }
    }

    const createScenario = async () => {

        try
        {
            if( scenarioLabel === '' || scenarioFile === '' )
            {
                setError("Veuillez remplir tous les champs requis!");
            }
            else
            {
                await SendPost(`api/v1/scenarios`, {
                    'creatorId': connectedUser.id,
                    'riskId': id,
                    'label': scenarioLabel,
                    'file': scenarioFile
                });

                setScenarioLabel('');
                setScenarioFile('');

                await getScenarios().finally(() => setIsDataLoaded(true));

            }

        }
        catch(Exception)
        {
            setError("Une erreur s'est produite pendant l'action.");
        }
    }

    const createTask = async () => {

        try
        {

            if( taskLabel === '' || taskUser === '' || taskDate === '' )
            {
                setError("Veuillez remplir tous les champs requis!");
            }
            else
            {
                await SendPost(`api/v1/tasks`, {
                    'creatorId': connectedUser.id,
                    'riskId': id,
                    'task': taskLabel,
                    'responsibleId': taskUser,
                    'deadline': taskDate
                });


                setTaskDate('');
                setTaskLabel('');
                setTaskUser('');

                await getTasks().finally(() => setIsDataLoaded(true));
            }

        }
        catch(Exception)
        {
            setError("Une erreur s'est produite pendant l'action.");
        }
    }

    const createLink = async () => {

        try
        {
            if( linkLabel === '' || linkUrl === '' )
            {
                setError("Veuillez remplir tous les champs requis!");
            }
            else
            {
                 await SendPost(`api/v1/links`, {
                    'creatorId': connectedUser.id,
                    'riskId': id,
                    'label': linkLabel,
                    'link': linkUrl
                });

                setLinkLabel('');
                setLinkUrl('');

                await getLinks().finally(() => setIsDataLoaded(true));
            }

        }
        catch(Exception)
        {
            setError("Une erreur s'est produite pendant l'action.");
        }
    }

    async function deleteAction (actionId) {
        if(window.confirm(`voulez-vous supprimer cette action ?`))
        {
            await SendDelete(`api/v1/actions/${actionId}`);
            const actionResponse = await SendGet(`api/v1/actions/risk/${id}`);
            setActionsList(actionResponse.data);
        }
    }

    async function updateStatus(status, actionId, action) {

        await SendPut(`api/v1/actions/${actionId}`, {
            ...action,
            status: status,
            completionDate: Date.now()
        });

        const actionResponse = await SendGet(`api/v1/actions/risk/${id}`) ?? null;
        setActionsList(actionResponse.data);
    }

    async function getScenarios() {
        setIsDataLoaded(false);
        const response = await SendGet(`api/v1/scenarios/risk/${id}`);
        setScenarioList(response.data);
    }

    async function getResources() {
        const response = await SendGet(`api/v1/resources/risk/${id}`);
        setResourcesList(response.data);
    }

    async function getLinks() {
        const response = await SendGet(`api/v1/links/risk/${id}`);
        setLinksList(response.data);
    }

    async function getTasks() {
        const response = await SendGet(`api/v1/tasks/risk/${id}`);
        setTasksList(response.data);
    }

    async function deleteScenario(id) {

        if(window.confirm('voulez-vous supprimer ce scénario ?'))
        {
            await SendDelete(`api/v1/scenarios/${id}`);
            await getScenarios();
            setIsDataLoaded(true);
        }
    }

    async function deleteResource(id) {

        if(window.confirm('voulez-vous supprimer cette ressource ?'))
        {
            await SendDelete(`api/v1/resources/${id}`);
            await getResources();
            setIsDataLoaded(true);
        }
    }

    async function deleteLink(id) {

        if(window.confirm('voulez-vous supprimer ce lien ?'))
        {
            await SendDelete(`api/v1/links/${id}`);
            await getLinks();
            setIsDataLoaded(true);
            setIsDataLoaded(true);
        }
    }

    async function deleteTask(id) {

        if( window.confirm('voulez-vous supprimer cette tâche ?') )
        {
            try
            {
                await SendDelete(`api/v1/tasks/${id}`);
                await getTasks();
                setIsDataLoaded(true);
            }
            catch(error)
            {
                console.log(error);
            }
        }
    }

    async function deleteRisk() {

        if(window.confirm('voulez-vous supprimer ce risque ?'))
        {
            await SendDelete(`api/v1/risks/${id}`);
            navigate(`/grid`);
        }
    }

    async function getMapping() {

        if( !isEmpty(risk) )
        {
            const response = await SendGet(`api/v1/mappings/${risk.mappingId}`);
            if(!isEmpty(response.data))
            {
                setMapping(response.data);
            }
        }
    }

    async function updateRiskReevaluate() {

        await SendPut(`api/v1/risks/${id}`, {
            ...risk,
            reevaluate: 1
        });
    }

    async function getRisk() {
        const riskResponse = await SendGet(`api/v1/risks/${id}`);
        if( !isEmpty(riskResponse.data) ) { setRisk(riskResponse.data); }
    }

    async function handleCompleted(id, action) {

        updateStatus('réalisée', id, action);
        await updateRiskReevaluate();
        await getRisk();
    }

    function getUserNameById(id)
    {
        let user = users.filter(user => user.id === id);

        if (user.length > 0)
        {
            return `${user[0].firstName} ${user[0].lastName}`;
        }
        else
        {
            return "N/A";
        }
    }

    useEffect(() => {
        async function fetchData() {
            await Promise.all([getMapping(), getScenarios(), getResources(), getLinks(), getTasks()]);
            setIsDataLoaded(true);
        }
        fetchData();
    }, [activeAssessment]);

    if (!isDataLoaded) {
        return <div>Loading...</div>;
    }

    const styles = {
        container: {
            padding: 0,
            margin: 0
        },
        bodyContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        menuContainer: {
        },
        detailContainer: {
            flex: 5,
            padding: 20,
            backgroundColor: '#FFFFFF'
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            float: 'right',
            paddingRight: 10,
            paddingTop: 5
        },
        printButton: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            color: '#FFFFFF',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 13,
            height: 35,
            fontWeight: 700
        },
        updateButton: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            color: '#FFFFFF',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 13,
            height: 35,
            fontWeight: 700
        },
        deleteButton: {
            backgroundColor: '#D90303',
            color: '#FFFFFF',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 13,
            height: 35,
            fontWeight: 700
        },
        iconStyle: {
            fontSize: 18,
            verticalAlign: 'middle'
        },
        informationContainer: {
            marginTop: 20
        },
        informationHeader: {
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 10,
            paddingLeft: 10
        },
        textLabel: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            padding: 7,
            marginRight: 5,
            fontSize: 12,
            fontWeight: 'normal',
            fontStyle: 'normal',
            color: assessments.length > 0 ? '#FFFFFF' : '#000000',
            borderRadius: '5px 5px 5px 5px'
        },
        informationBody: {
            backgroundColor: assessments.length > 0 ? getColorHexCode(activeAssessment.riskBrute) : '#FFFFFF',
            borderRadius: '5px 5px 5px 5px'
        },
        titleContainer: {
            textAlign: 'center',
            padding: 20,
            paddingBottom: 40
        },
        mainTitle: {
            fontSize: 18,
            fontWeight: 700,
            color: '#FFFFFF',
            textTransform: 'uppercase'
        },
        contentContainer: {
            display: 'flex',
            paddingBottom: 20,
            justifyContent: 'center'
        },
        contentRow: {
            width: 320,
            textAlign: 'center'
        },
        contentTextLabel: {
            fontSize: 14,
            color: '#FFFFFF',
            fontWeight: 700,
            marginBottom: 5
        },
        fullRate: {
            fontSize: 20,
            marginRight: 5,
            verticalAlign: 'middle',
            color: '#FFFFFF',
            backgroundColor: '#FFFFFF',
            borderRadius: '50%'
        },
        emptyRate: {
            fontSize: 20,
            marginRight: 5,
            verticalAlign: 'middle',
            color: '#FFFFFF'
        },
        contentExtraContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        contentExtraRow: {
            display: 'flex',
            flex: 1,
            marginBottom: 4
        },
        contentExtraCol: {
            flex: 3,
            textAlign: 'left'
        },
        dateContainer: {
            textAlign: 'center',
            paddingBottom: 30,
            paddingTop: 10
        },
        versionDate: {
            backgroundColor: assessments.length > 0 ? '#877F00' : '#FFFFFF',
            color: assessments.length > 0 ? '#FFFFFF' : '#000000',
            fontWeight: 'bold',
            fontSize: 14,
            padding: 10,
            borderRadius: 5
        },
        riskInfoContainer: {
            width: 410,
            margin: '0px auto',
            padding: 20,
            border: assessments.length > 0 ? 'solid 1px ' + getColorHexCode(activeAssessment.riskBrute) : 'solid 1px #FFFFFF',
            borderRadius: '0px 0px 10px 10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px'
        },
        criticSpan: {
            backgroundColor: '#E13F3F',
            width: 100,
            height: 20,
            borderRadius: 5,
            flex: 1
        },
        buttonsContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 40
        },
        outlinedButton: {
            backgroundColor: '#FFFFFF',
            fontSize: 12,
            border: 'solid 1px #000000',
            borderRadius: 5,
            color: '#000000',
            fontWeight: 700,
            marginRight: 20,
            padding: 10,
            cursor: 'pointer',
            textTransform: 'uppercase'
        },
        descriptionText: {
            color: '#767676',
            fontWeight: 400,
            lineHeight: 1.3
        },
        descriptionContainer: {
            marginTop: 20
        },
        instrumentContainer: {
        },
        instrumentRow: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 20
        },
        instrumentCol: {
            border: 'solid 1px #62CFFC',
            borderRadius: '20px 20px 20px 20px',
            flex: 2.5,
            minHeight: 250
        },
        formTitleContainer: {
            backgroundColor: '#62CFFC',
            padding: 20,
            borderRadius: '20px 0px 0px 0px',
            textAlign: 'center',
            fontWeight: 600,
            color: '#FFFFFF'
        },
        formButton: {
            backgroundColor: '#2784FF',
            color: '#FFFFFF',
            padding: 20,
            textAlign: 'left',
            height: 18,
            cursor: 'pointer'
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        formInput: {
            flex: 1,
            margin: 15,
            height: 40,
            marginTop: 5,
            marginBottom: 0,
            borderRadius: 20,
            fontSize: 14,
            lineHeight: 2.5,
            padding: 10,
            paddingLeft: 15,
            color: '#0443AE',
            fontWeight: 700,
            border: 'solid 1px #D6D7D9'
        },
        selectFormInput: {
            flex: 1,
            margin: 15,
            marginTop: 5,
            marginBottom: 0,
            borderRadius: 5,
            fontSize: 14,
            lineHeight: 10,
            paddingLeft: 15,
            paddingTop: 10,
            paddingBottom: 10,
            color: '#000000',
            fontWeight: 600,
            border: 'solid 1px #E2E2E2',
            backgroundColor: '#FFFFFF',
            width: '90%'
        },
        formInputPicker: {
            flex: 1,
            margin: 15,
            height: 40,
            marginTop: 5,
            borderRadius: 20,
            fontSize: 14,
            lineHeight: 2.5,
            paddingLeft: 15,
            color: '#0443AE',
            fontWeight: 400,
            border: 'solid 1px #0443AE',
            cursor: 'pointer'
        },
        inputField: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #D6D7D9',
            color: '#000000',
            paddingLeft: 10,
            width: '95%'
        },
        formLabel: {
            marginLeft: 20,
            fontSize: 13,
            marginTop: 20,
            fontWeight: 700,
            color: '#2988FF'
        },
        formButtonContainer: {
            textAlign: 'center',
            marginBottom: 40,
            marginTop: 20
        },
        annulerButton: {
            backgroundColor: '#D90303',
            color: '#FFFFFF',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            height: 35,
            fontWeight: 700
        },
        creeButton: {
            backgroundColor: '#2784FF',
            color: '#FFFFFF',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            height: 35,
            fontWeight: 700
        },
        emptyTextContainer: {
            textAlign: 'center',
            marginBottom: 20
        },
        emptyText: {
            color: '#104A95',
            fontSize: 12,
            fontWeight: 600,
            textDecoration: 'italic'
        },
        evaluationContainer: {
        },
        textTitle: {
            color: '#000000',
            fontWeight: 600,
            fontSize: 20,
            marginTop: 20,
            textTransform: 'uppercase'
        },
        actionContainer: {
        },
        menuInstrument: {
            marginBottom: 20
        },
        tableInstrument: {
            padding: 10,
            borderColor: '#E2E2E2',
            borderCollapse: 'collapse',
            fontSize: 14,
            fontWeight: 400,
            borderRadius: 5
        },
        tdTableInstrument: {
            padding: 10
        },
        tableHeaderColumn: {
            fontWeight: 600,
            textAlign: 'left'
        },
        btnAction: {
            backgroundColor: '#FFFFFF',
            color: '#000000',
            border: 'solid 1px #000000',
            borderRadius: 5
        },
        btnActionDelete: {
            backgroundColor: '#FFFFFF',
            color: 'red',
            border: 'solid 1px red',
            borderRadius: 5
        },
        btnIconDelete: {
            color: 'red',
            fontSize: 18,
            fontWeight: 600,
            padding: 2,
            cursor: 'pointer'
        },
        btnIcon: {
            color: '#000000',
            fontSize: 18,
            fontWeight: 600,
            padding: 2,
            cursor: 'pointer'
        },
        actionColumn: {
            width: 30,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center'
        },
        btnInstrument: {
            backgroundColor: '#FFFFFF',
            color: '#000000',
            padding: 8,
            border: 'solid 1px #000000',
            borderRadius: 5,
            marginRight: 10,
            cursor: 'pointer',
            fontSize: 12
        }
    }

    return (

        <div style={styles.container}>

            <Error isVisible={error != null} errorMessage={error} handleClose={() => setError(null)} />

            <RiskTopMenu setShowSideMenu={() => setShowSideMenu(!showSideMenu)} showSideMenu={showSideMenu} />
            <div style={styles.bodyContainer}>
                <div style={styles.menuContainer}>
                    <RiskSideMenu showSideMenu={showSideMenu} />
                </div>
                <div style={styles.detailContainer}>
                    {
                        risk && risk.reevaluate === 1 &&
                        <span style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 700, backgroundColor: '#D90303', borderRadius: 15, padding: 10 }}>
                            <span className="material-symbols-outlined" style={styles.iconStyle}>close</span> Besoin d'une réévaluation
                        </span>
                    }
                    <div style={{ ...styles.buttonContainer, borderRadius: 15, marginTop: 40 }}>
                        { hasPrint && <button style={styles.printButton}><span className="material-symbols-outlined" style={styles.iconStyle}>print</span> Imprimer</button> }
                        { hasEvaluate && <button style={styles.updateButton} onClick={() => navigate(`/assessments/create/${id}`)}><span className="material-symbols-outlined" style={styles.iconStyle}>star</span> Evaluer</button> }
                        { hasEdit && !isEmpty(cartographie.value) && <button onClick={() => navigate(`/risks/edit/${risk.id}`)} style={styles.updateButton}><span className="material-symbols-outlined" style={styles.iconStyle}>edit_square</span> Modifier</button> }
                        { hasDelete && <button onClick={() => deleteRisk()} style={styles.deleteButton}><span className="material-symbols-outlined" style={styles.iconStyle}>delete</span></button> }
                    </div>
                    <div style={styles.informationContainer}>
                        {
                            assessments.length > 0 &&
                            <div style={styles.informationBody}>
                                <div style={styles.informationHeader}>
                                    <span style={styles.textLabel}>
                                        <span className="material-symbols-outlined"
                                              style={{ ...styles.iconStyle, fontSize: 15, marginRight: 3 }}>linked_services</span> {mapping && mapping.name}
                                    </span>
                                    <span style={styles.textLabel}>
                                        <span className="material-symbols-outlined"
                                              style={styles.iconStyle}>search</span>
                                        <span style={{textTransform: 'uppercase'}}>{risk.origin}</span>
                                    </span>
                                    <span style={styles.textLabel}>
                                        <span className="material-symbols-outlined"
                                              style={styles.iconStyle}>person</span> {`${riskOwner.firstName} ${riskOwner.lastName}`}
                                    </span>
                                    {assessments.length === 0 && <span style={{
                                        ...styles.textLabel,
                                        textTransform: 'uppercase',
                                        color: 'red',
                                        backgroundColor: 'white',
                                        fontWeight: 'bold'
                                    }}><span className="material-symbols-outlined" style={styles.iconStyle}>block</span> Risque non évalué</span>}
                                </div>
                                <div style={styles.titleContainer}>
                                    <span style={styles.mainTitle}>{risk.frTitle}</span>
                                </div>
                                <div style={styles.contentContainer}>
                                    <div style={{...styles.contentRow, width: 150, marginLeft: 50, marginRight: 50}}>
                                        <span style={styles.contentTextLabel}>IMPACT</span>
                                        <ProgressBar labelSize={9}
                                                     className="progressBarwrapper"
                                                     labelColor={getColorHexCode(activeAssessment.riskBrute)}
                                                     baseBgColor="rgba(255, 255, 255, 0.3)"
                                                     bgColor="#FFFFFF"
                                                     completed={Math.round(((activeAssessment.impact / 4) * 100))} />
                                    </div>
                                    <div style={{...styles.contentRow, width: 150, marginLeft: 50, marginRight: 50}}>
                                        <span style={styles.contentTextLabel}>OCCURRENCE</span>
                                        <ProgressBar labelSize={9}
                                                     className="progressBarwrapper"
                                                     labelColor={getColorHexCode(activeAssessment.riskBrute)}
                                                     baseBgColor="rgba(255, 255, 255, 0.3)"
                                                     bgColor="#FFFFFF"
                                                     completed={Math.round(((activeAssessment.occurrence / 4) * 100))} />
                                    </div>
                                    <div style={{...styles.contentRow, width: 150}}>
                                        <span style={styles.contentTextLabel}>MAITRISE</span>
                                        <ProgressBar labelSize={9}
                                                     className="progressBarwrapper"
                                                     labelColor={getColorHexCode(activeAssessment.riskBrute)}
                                                     baseBgColor="rgba(255, 255, 255, 0.3)"
                                                     bgColor="#FFFFFF"
                                                     completed={Number(activeAssessment.maitrise)} />
                                    </div>
                                </div>
                                <div style={styles.dateContainer}>
                                <span style={{...styles.versionDate, backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
                                    <span className="material-symbols-outlined"
                                          style={styles.iconStyle}>calendar_month</span> Version {parseInt(activeAssessment.version)} | {formatDate(activeAssessment.assesementDate)}
                                </span>
                                </div>
                            </div>
                        }
                        {
                            assessments.length === 0 &&
                            <div style={{...styles.informationBody, backgroundColor: '#55657B'}}>
                                <div style={styles.titleContainer}>
                                    <span style={styles.mainTitle}>{risk.frTitle}</span>
                                </div>
                                <div style={styles.contentContainer}>
                                    <div style={{...styles.contentRow, width: 150, marginLeft: 50, marginRight: 50}}>
                                        <span style={styles.contentTextLabel}>IMPACT</span>
                                        <ProgressBar labelSize={9}
                                                     className="progressBarwrapper"
                                                     labelColor={getColorHexCode(null)}
                                                     baseBgColor="rgba(255, 255, 255, 0.3)"
                                                     bgColor="#FFFFFF"
                                                     completed={0}/>
                                    </div>
                                    <div style={{...styles.contentRow, width: 150, marginLeft: 50, marginRight: 50}}>
                                        <span style={styles.contentTextLabel}>OCCURRENCE</span>
                                        <ProgressBar labelSize={9}
                                                     className="progressBarwrapper"
                                                     labelColor={getColorHexCode(null)}
                                                     baseBgColor="rgba(255, 255, 255, 0.3)"
                                                     bgColor="#FFFFFF"
                                                     completed={0}/>
                                    </div>
                                    <div style={{...styles.contentRow, width: 150}}>
                                        <span style={styles.contentTextLabel}>MAITRISE</span>
                                        <ProgressBar labelSize={9}
                                                     className="progressBarwrapper"
                                                     labelColor={getColorHexCode(null)}
                                                     baseBgColor="rgba(255, 255, 255, 0.3)"
                                                     bgColor="#FFFFFF"
                                                     completed={0}/>
                                    </div>
                                </div>
                                <div style={styles.dateContainer}>
                                <span style={{
                                    ...styles.versionDate,
                                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                    color: '#FFFFFF'
                                }}>
                                    <span className="material-symbols-outlined"
                                          style={styles.iconStyle}>calendar_month</span> Version {parseInt(1)} | {formatDate(Date.now())}
                                </span>
                                </div>
                            </div>
                        }
                    </div>
                    {
                        assessments.length > 0 &&
                        <div style={styles.riskInfoContainer}>

                            <div style={{flex: 3, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                                <div style={{width: 60, height: 60}}>
                                    <CircularProgressbar
                                        value={100}
                                        text={ Number( activeAssessment.riskBrute ) }
                                        size={40}
                                        styles={buildStyles({
                                            rotation: 0,
                                            strokeLinecap: 'butt',
                                            textSize: '24px',
                                            pathTransitionDuration: 0.5,
                                            pathColor: getColorHexCode( Number( activeAssessment.riskBrute ) ),
                                            textColor: getColorHexCode( Number( activeAssessment.riskBrute ) ),
                                            trailColor: '#f7f6f2',
                                        })}
                                    />
                                </div>

                                <p style={{
                                    color: getColorHexCode( Number( activeAssessment.riskBrute ) ),
                                    fontSize: 13,
                                    fontWeight: 700
                                }}>Score</p>

                            </div>

                            { /*<div style={{flex: 3, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                                <div style={{width: 60, height: 60}}>
                                    <CircularProgressbar
                                        value={100}
                                        text={ "" + activeAssessment.riskBrute }
                                        size={40}
                                        styles={buildStyles({
                                            rotation: 0,
                                            strokeLinecap: 'butt',
                                            textSize: '24px',
                                            pathTransitionDuration: 0.5,
                                            pathColor: getColorHexCode( Number( activeAssessment.riskBrute ) ),
                                            textColor: getColorHexCode( Number( activeAssessment.riskBrute ) ),
                                            trailColor: '#f7f6f2',
                                        })}
                                    />
                                </div>

                                <p style={{
                                    color: getColorHexCode( Number( activeAssessment.riskBrute ) ),
                                    fontSize: 13,
                                    fontWeight: 700
                                }}>Risque Net</p>

                            </div> */ }

                        </div>
                    }
                    <div style={styles.buttonsContainer}>
                        <button className={ activeForm === "general" ? "btn-main" : "" } style={styles.outlinedButton} onClick={() => setActiveForm("general")}>Général</button>
                        <button className={ activeForm === "instrument" ? "btn-main" : "" } style={styles.outlinedButton} onClick={() => setActiveForm("instrument")}>Instruments de contrôle</button>
                        <button className={ activeForm === "evaluation" ? "btn-main" : "" } style={styles.outlinedButton} onClick={() => setActiveForm("evaluation")}>ÉVALUATIONS</button>
                        <button className={ activeForm === "action" ? "btn-main" : "" } style={styles.outlinedButton} onClick={() => setActiveForm("action")}>ACTIONS</button>
                        <button className={ activeForm === "comments" ? "btn-main" : "" } style={styles.outlinedButton} onClick={() => setActiveForm("comments")}>COMMENTAIRES</button>
                    </div>

                    {activeForm === "general" &&
                        <div style={styles.descriptionContainer}>
                            <span style={styles.textTitle}>Contexte et description du risque</span>
                            <p style={styles.descriptionText}>{risk.frDesc}</p>
                        </div>}

                    {activeForm === "instrument" && <div style={styles.instrumentContainer}>
                        <div style={styles.instrumentRow}>

                            <div style={styles.menuInstrument}>

                                <button className={ showScenario ? "btn-main" : "" } style={styles.btnInstrument} onClick={() => {
                                    setShowScenario(true);
                                    setShowResource(false);
                                    setShowLien(false);
                                    setShowTravaux(false);
                                }}>SCENARIOS</button>

                                <button className={ showResource ? "btn-main" : "" } style={styles.btnInstrument} onClick={() => {
                                    setShowScenario(false);
                                    setShowResource(true);
                                    setShowLien(false);
                                    setShowTravaux(false);
                                }}>RESSOURCES</button>

                                <button className={ showLien ? "btn-main" : "" } style={styles.btnInstrument} onClick={() => {
                                    setShowScenario(false);
                                    setShowResource(false);
                                    setShowLien(true);
                                    setShowTravaux(false);
                                }}>POUR ALLER PLUS LOIN</button>

                                <button className={ showTravaux ? "btn-main" : "" } style={styles.btnInstrument} onClick={() => {
                                    setShowScenario(false);
                                    setShowResource(false);
                                    setShowLien(false);
                                    setShowTravaux(true);
                                }}>TRAVAUX ENVISAGE</button>
                            </div>

                            {/* Scenario */}
                            {
                                showScenario &&
                                <div>
                                    <table width="100%" style={styles.tableInstrument} border="1" cellPadding={10}>

                                        <thead>
                                            <tr>
                                                <td style={styles.tableHeaderColumn} width={20}>#</td>
                                                <td style={styles.tableHeaderColumn}>Libelle</td>
                                                <td style={styles.tableHeaderColumn}>Fichier</td>
                                                <td></td>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            <tr>
                                                <td style={styles.tdTableInstrument}>
                                                </td>
                                                <td style={styles.tdTableInstrument}>
                                                    <input onChange={e => setScenarioLabel(e.target.value)} value={scenarioLabel} type="text" style={styles.inputField} placeholder="Libelle ..." />
                                                </td>
                                                <td style={styles.tdTableInstrument}>
                                                    <input onChange={e => setScenarioFile(e.target.value)} value={scenarioFile} type="text" style={styles.inputField} placeholder="Fichier URL ..." />
                                                </td>
                                                <td style={styles.actionColumn}>
                                                    <button className="btn-primary-custom" style={styles.btnAction} onClick={createScenario}>
                                                        <span className="material-symbols-outlined" style={styles.btnIcon}>add</span>
                                                    </button>
                                                </td>
                                            </tr>

                                            {
                                                scenarioList && scenarioList.map( row => {

                                                    return (
                                                        <tr key={row.id}>
                                                            <td>
                                                                { row.id }
                                                            </td>
                                                            <td>
                                                                { row.label }
                                                            </td>
                                                            <td>
                                                                <a href={ row.file }>{ row.file }</a>
                                                            </td>
                                                            <td align="center">
                                                                <button className="btn-primary-custom" style={styles.btnActionDelete} onClick={() => deleteScenario(row.id)}>
                                                                    <span className="material-symbols-outlined" style={styles.btnIconDelete}>delete</span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )

                                                })
                                            }

                                        </tbody>

                                    </table>
                                </div>
                            }

                            {/* Ressources */}
                            {
                                showResource &&
                                <div>
                                    <table width="100%" style={styles.tableInstrument} border="1" cellPadding={10}>
                                        <thead>
                                            <tr>
                                                <td style={styles.tableHeaderColumn} width={20}>#</td>
                                                <td style={styles.tableHeaderColumn}>Libelle</td>
                                                <td style={styles.tableHeaderColumn}>Source</td>
                                                <td style={styles.tableHeaderColumn}>Language</td>
                                                <td></td>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            <tr>
                                                <td style={styles.tdTableInstrument}>
                                                </td>
                                                <td style={styles.tdTableInstrument}>
                                                    <input type="text" style={styles.inputField} value={resourceLabel} placeholder="Libelle ..." onChange={e => setResourceLabel(e.target.value)}/>
                                                </td>
                                                <td style={styles.tdTableInstrument}>
                                                    <input type="text" style={styles.inputField} value={resourceSource} placeholder="Fichier URL ..." onChange={e => setResourceSource(e.target.value)}/>
                                                </td>
                                                <td style={styles.tdTableInstrument}>
                                                    <select style={styles.selectFormInput} value={resourceLanguage} onChange={e => setResourceLanguage(e.target.value)}>
                                                        <option value="FR" selected={true}>FR</option>
                                                        <option value="EN">EN</option>
                                                    </select>
                                                </td>
                                                <td align="center" style={styles.actionColumn}>
                                                    <button className="btn-primary-custom" style={styles.btnAction} onClick={createResource}>
                                                        <span className="material-symbols-outlined" style={styles.btnIcon}>add</span>
                                                    </button>
                                                </td>
                                            </tr>

                                            {
                                                resourcesList && resourcesList.map( row => {

                                                    return (
                                                        <tr key={row.id}>
                                                            <td>{ row.id }</td>
                                                            <td>{ row.label }</td>
                                                            <td>
                                                                <a href={ row.source }>{ row.source }</a>
                                                            </td>
                                                            <td>{ row.language }</td>
                                                            <td align="center">
                                                                <button className="btn-primary-custom" style={styles.btnActionDelete} onClick={() => deleteResource(row.id)}>
                                                                    <span className="material-symbols-outlined" style={styles.btnIconDelete}>delete</span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )

                                                })
                                            }

                                        </tbody>

                                    </table>
                                </div>
                            }

                            {/* POUR ALLER PLUS LOIN */}
                            {
                                showLien &&
                                <div>
                                    <table width="100%" style={styles.tableInstrument} border="1" cellPadding={10}>

                                        <thead>
                                            <tr>
                                                <td style={styles.tableHeaderColumn} width={20}>#</td>
                                                <td style={styles.tableHeaderColumn}>Libelle</td>
                                                <td style={styles.tableHeaderColumn}>Lien</td>
                                                <td></td>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            <tr>
                                                <td style={styles.tdTableInstrument}>
                                                </td>
                                                <td style={styles.tdTableInstrument}>
                                                    <input type="text" style={styles.inputField} value={linkLabel} placeholder="Libelle ..." onChange={ e => setLinkLabel(e.target.value) } />
                                                </td>
                                                <td style={styles.tdTableInstrument}>
                                                    <input type="text" style={styles.inputField} value={linkUrl} placeholder="Fichier URL ..." onChange={ e => setLinkUrl(e.target.value) } />
                                                </td>
                                                <td style={styles.actionColumn}>
                                                    <button className="btn-primary-custom" style={styles.btnAction} onClick={createLink}>
                                                        <span className="material-symbols-outlined" style={styles.btnIcon}>add</span>
                                                    </button>
                                                </td>
                                            </tr>

                                            {
                                                linksList && linksList.map( row => {
                                                    return (
                                                        <tr key={row.id}>
                                                            <td>{ row.id }</td>
                                                            <td>{ row.label }</td>
                                                            <td><a href={ row.link } >{ row.link }</a></td>
                                                            <td>
                                                                <button className="btn-primary-custom" style={styles.btnActionDelete} onClick={() => deleteLink(row.id)}>
                                                                    <span className="material-symbols-outlined" style={styles.btnIconDelete}>delete</span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>

                                    </table>
                                </div>
                            }

                            { /* TRAVAUX ENVISAGE */}
                            {
                                showTravaux &&
                                <div>
                                    <table width="100%" style={styles.tableInstrument} border="1" cellPadding={10}>

                                        <thead>
                                            <tr>
                                                <td style={styles.tableHeaderColumn} width={20}>#</td>
                                                <td style={styles.tableHeaderColumn}>Tache</td>
                                                <td style={styles.tableHeaderColumn}>Responsable</td>
                                                <td style={styles.tableHeaderColumn}>Deadline</td>
                                                <td></td>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            <tr>
                                                <td style={styles.tdTableInstrument}>
                                                </td>
                                                <td style={styles.tdTableInstrument}>
                                                    <input type="text" style={styles.inputField} value={taskLabel} placeholder="Tache ..." onChange={ e => setTaskLabel(e.target.value) } />
                                                </td>
                                                <td style={styles.tdTableInstrument}>
                                                    <select style={styles.selectFormInput} value={taskUser} onChange={e => setTaskUser(e.target.value) } >
                                                        <option value="" selected={true}></option>
                                                        {
                                                            users.map( user => {
                                                                return (
                                                                    <option style={styles.selectFormInput} key={ user.id } value={ user.id }>{ `${user.firstName} ${user.lastName}` }</option>
                                                                )
                                                            } )
                                                        }
                                                    </select>
                                                </td>
                                                <td style={styles.tdTableInstrument}>
                                                    <input type="date" style={styles.inputField} value={taskDate} placeholder="Deadline ..." onChange={ e => setTaskDate(e.target.value) } />
                                                </td>
                                                <td style={styles.actionColumn}>
                                                    <button className="btn-primary-custom" style={styles.btnAction} onClick={ createTask } >
                                                        <span className="material-symbols-outlined" style={styles.btnIcon}>add</span>
                                                    </button>
                                                </td>
                                            </tr>

                                            {
                                                tasksList && tasksList.map( row => {

                                                    return (
                                                        <tr key={ row.id } >
                                                            <td>{ row.id }</td>
                                                            <td>{ row.task }</td>
                                                            <td>{ getUserNameById(row.responsibleId) }</td>
                                                            <td>{ convertDateToString(row.deadline) }</td>
                                                            <td>
                                                                <button className="btn-primary-custom" style={styles.btnActionDelete} onClick={() => deleteTask(row.id)}>
                                                                    <span className="material-symbols-outlined" style={styles.btnIconDelete}>delete</span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )

                                                })
                                            }

                                        </tbody>

                                    </table>
                                </div>
                            }

                        </div>
                    </div>}

                    {activeForm === "evaluation" && <div style={styles.evaluationContainer}>
                        <p style={styles.textTitle}>Précédentes évaluations</p>
                        {
                            assessments.map(item => {

                                return (
                                    <EvaluationCard
                                        key={item.id}
                                        item={item}
                                        origin={risk.origin}
                                    />
                                )
                            })
                        }
                    </div>

                    }

                    {activeForm === "action" && <div style={styles.actionContainer}>
                        <p style={styles.textTitle}>Actions proposées <button className="btn-primary-custom"
                                                                              onClick={() => navigate(`/action/create/${id}`)}
                                                                              style={{
                                                                                  backgroundColor: '#FFFFFF',
                                                                                  border: 'solid 1px #000000',
                                                                                  fontSize: 11,
                                                                                  fontWeight: 600,
                                                                                  color: '#000000',
                                                                                  padding: 5,
                                                                                  borderRadius: 5,
                                                                                  paddingRight: 10,
                                                                                  marginLeft: 20,
                                                                                  cursor: 'pointer',
                                                                                  paddingTop: 8,
                                                                                  paddingBottom: 8
                                                                              }}><span
                            className="material-symbols-outlined" style={{
                            ...styles.iconStyle,
                            flex: 0.2,
                            fontSize: 20,
                            verticalAlign: 'middle',
                            marginTop: -4,
                            color: '#000000'
                        }}>add</span> NOUVELLE ACTION</button></p>
                        {actionsList == null && <p style={{fontSize: 11, color: '#094492', fontWeight: 600}}><span
                            className="material-symbols-outlined" style={{
                            ...styles.iconStyle,
                            flex: 0.2,
                            fontSize: 20,
                            verticalAlign: 'middle',
                            marginTop: -4,
                            color: '#094492'
                        }}>info</span> Acune action pour ce risque.</p>}

                        {
                            actionsList && actionsList.map(action => {
                                return (
                                    <ActionComponent key={action.id} actionId={action.id} onCompleted={() => handleCompleted(action.id, action)} content={action.frContent} goal={action.frGoal} onDelete={() => deleteAction(action.id)} status={action.status} onIrrelevant={() => updateStatus('non pertinente', action.id, action)} onEdit={() => navigate(`/action/edit/${action.riskId}/${action.id}`)} risk={risk} />
                                )
                            })
                        }

                    </div>
                    }

                    {
                        activeForm === "comments" && <CommentsComponent riskId={id} />
                    }

                </div>
            </div>
        </div>
    );
}
