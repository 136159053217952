import React, {useEffect, useState} from 'react';
import {getColorHexCode, isEmpty, SendGet} from "../State/Helper";
import {useAuth} from "../State/useAuth";
import RiskPopUp from "./RiskPopUp";

const OccurrenceImpactShape = () => {

    const [riskList, setRiskList] = React.useState([]);
    const { cartographie, businessUnit, country, company, businessActivity, searchKeyword, rankFilter } = useAuth();
    const [showDetail, setShowDetail] = useState(false);
    const [selectedImpOc, setSelectedImpOc] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getRiskList();
            } catch (error) {
                console.error(error);
            }
        };
        fetchData().catch(e => console.log(`Error occurred: `, e));
    }, [rankFilter, cartographie, businessUnit, country, company, businessActivity]);

    async function getRiskList() {

        let response = await SendGet(`api/v1/risks/currentuser`);

        if (!isEmpty(response.data))
        {
            let parsedCartographie = cartographie.value !== null ? parseInt(cartographie.value) : null;
            let parsedBusinessUnit = businessUnit.value !== null ? parseInt(businessUnit.value) : null;
            let parsedCountry = country.value !== null ? parseInt(country.value) : null;
            let parsedCompany = company.value !== null ? parseInt(company.value) : null;
            let parsedBusinessActivity = businessActivity.value !== null ? parseInt(businessActivity.value) : null;
            let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

            let risks = response.data.filter(item =>
                (parsedCartographie === null || parseInt(item.mappingId) === parsedCartographie) &&
                (parsedBusinessUnit === null || parseInt(item.buId) === parsedBusinessUnit) &&
                (parsedCountry === null || parseInt(item.countryId) === parsedCountry) &&
                (parsedCompany === null || parseInt(item.companyId) === parsedCompany) &&
                (parsedBusinessActivity === null || parseInt(item.baId) === parsedBusinessActivity) &&
                (parseSearchKeyword === null || item.enTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase())) &&
                (parseSearchKeyword === null || item.frTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase()))
            );

            let assessedRisks = [];

            await Promise.all(risks.map(async (risk) => {
                assessedRisks.push({ ...risk, calcs: await getAssessment(risk.id) });
            }));

            let evaluatedRisks = assessedRisks.filter(item => !isEmpty(item.calcs));
            evaluatedRisks = evaluatedRisks.sort((a, b) => b.credate - a.credate);

            let nonEvaluatedRisks = assessedRisks.filter(item => isEmpty(item.calcs));

            // Filter by the risk rank
            if( rankFilter !== null && rankFilter !== "not_evaluated" )
            {
                const filtered = [];

                evaluatedRisks.map(item => {

                    if( Number(rankFilter) === 1 )
                    {
                        if( Number(item.calcs.riskBrute) > 360 )
                        {
                            filtered.push(item);
                        }
                    }
                    else if( Number(rankFilter) === 2 )
                    {
                        if( Number(item.calcs.riskBrute) <= 360 && Number(item.calcs.riskBrute) > 160 )
                        {
                            filtered.push(item);
                        }
                    }
                    else if( Number(rankFilter) === 3 )
                    {
                        if( Number(item.calcs.riskBrute) <= 160 )
                        {
                            filtered.push(item);
                        }
                    }

                });

                setRiskList(filtered);
            }
            else if( rankFilter === null )
            {
                setRiskList(evaluatedRisks);
            }

        } else {
            setRiskList([]);
        }
    }

    async function getAssessment(riskId) {
        let assessments = await SendGet(`api/v1/assessments/risk/${riskId}`);
        if (!isEmpty(assessments)) {
            return assessments.data.reduce((max, obj) => (parseInt(obj.version) > parseInt(max.version) ? obj : max), assessments.data[0]);
        } else {
            return null;
        }
    }

    const width = 800;
    const height = 600;
    const xValues = [1, 2, 3, 4];
    const yValues = [1, 2, 3, 4];

    // Set the margins for the chart
    const margin = { top: 50, right: 50, bottom: 50, left: 50 };

    // Calculate the space available for the chart inside the margins
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    // Calculate the width and height of each rectangle (cell)
    const rectWidth = chartWidth / xValues.length;
    const rectHeight = chartHeight / yValues.length;

    // Function to determine the color based on the value
    const getColor = (value) => {
        if (value >= 1 && value <= 4) return getColorHexCode(160);
        if (value > 4 && value <= 8) return getColorHexCode(240);
        if (value > 8 && value <= 12) return "#D68434";
        if (value > 12 && value <= 16) return getColorHexCode(420);
        return 'transparent'; // Default in case of error
    };

    // Group objects by their impact and occurrence
    const groupedData = riskList.reduce((acc, item) => {
        const key = `${item.calcs.impact}-${item.calcs.occurrence}`;
        if (!acc[key]) {
            acc[key] = { impact: item.calcs.impact, occurrence: item.calcs.occurrence, count: 0 };
        }
        acc[key].count += 1;
        return acc;
    }, {});

    return (
        <>
            <RiskPopUp isOpen={showDetail} handleClose={() => setShowDetail(!showDetail)} selectedImpOc={selectedImpOc} selectedCircle={null} />
            <svg width={width} height={height} style={{ margin: '0px auto' }}>

                {/* Labels */}
                <text x={width / 2} y={height - 10} textAnchor="middle" fontSize="16" style={{ fontWeight: 'bold' }}>Occurrence</text>
                <text x={15} y={height / 2} textAnchor="middle" fontSize="16" transform={`rotate(-90 15, ${height / 2})`} style={{ fontWeight: 'bold' }}>Impact</text>

                {/* Drawing the grid with color */}
                {yValues.map((yVal, yIdx) => (
                    xValues.map((xVal, xIdx) => {
                        const value = xVal * yVal; // Calculate the value for each cell
                        const fillColor = getColor(value); // Get the corresponding color

                        return (
                            <rect
                                key={`${xIdx}-${yIdx}`}
                                x={margin.left + xIdx * rectWidth}
                                y={margin.top + (yValues.length - yIdx - 1) * rectHeight} // Reverse the Y axis from bottom to top
                                width={rectWidth}
                                height={rectHeight}
                                fill={fillColor}
                                stroke="black"
                            />
                        );
                    })
                ))}

                {/* Display circles with counts */}
                {Object.values(groupedData).map((item, idx) => {

                    const { impact, occurrence, count } = item;

                    // Calculate the position for the circle
                    const cx = margin.left + (occurrence - 1) * rectWidth + rectWidth / 2;
                    const cy = margin.top + (yValues.length - impact) * rectHeight + rectHeight / 2;

                    return (
                        <g key={idx} onClick={() => { setSelectedImpOc(item); setShowDetail(true); }} style={{ cursor: 'pointer' }}>
                            <circle cx={cx} cy={cy} r={15} fill="#5D88ED" />
                            <text x={cx} y={cy + 5} textAnchor="middle" fill="white" fontSize="12">{count}</text>
                        </g>
                    );
                })}

                {/* X-axis labels */}
                {xValues.map((xVal, idx) => (
                    <text
                        key={xVal}
                        x={margin.left + idx * rectWidth + rectWidth / 2}
                        y={height - margin.bottom + 20}
                        textAnchor="middle"
                        fontSize="14"
                    >
                        {xVal}
                    </text>
                ))}

                {/* Y-axis labels */}
                {yValues.map((yVal, idx) => (
                    <text
                        key={yVal}
                        x={margin.left - 20}
                        y={margin.top + (yValues.length - idx - 1) * rectHeight + rectHeight / 2}
                        textAnchor="middle"
                        fontSize="14"
                    >
                        {yVal}
                    </text>
                ))}
            </svg>
        </>
    );
};

export default OccurrenceImpactShape;
