import RiskTopMenu from "../Components/RiskTopMenu";
import RiskSideMenu from "../Components/RiskSideMenu";
import { useEffect, useState } from "react";
import CircleMenu from "../Components/CircleMenu";
import {isEmpty, SendGet} from "../State/Helper";
import { useAuth } from "../State/useAuth";
import Paginator from "../Components/Paginator";
import NotificationBadge from "../Components/NotificationBadge";

export default function Grid() {

    const styles = {
        container: {
            backgroundColor: '#FFFFFF',
        },
        bodyContainer: {
            display: 'flex',
            marginLeft: 10
        },
        cardRow: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row'
        }
    }

    const [showSideMenu, setShowSideMenu] = useState(true);
    const [riskList, setRiskList] = useState([]);
    const {cartographie, businessUnit, country, company, businessActivity, searchKeyword, rankFilter} = useAuth();

    async function getRiskList() {

        let response = await SendGet(`api/v1/risks/currentuser`);

        if ( !isEmpty(response.data) ) {

            let parsedCartographie = cartographie.value !== null ? parseInt(cartographie.value) : null;
            let parsedBusinessUnit = businessUnit.value !== null ? parseInt(businessUnit.value) : null;
            let parsedCountry = country.value !== null ? parseInt(country.value) : null;
            let parsedCompany = company.value !== null ? parseInt(company.value) : null;
            let parsedBusinessActivity = businessActivity.value !== null ? parseInt(businessActivity.value) : null;
            let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

            let risks = response.data.filter(item =>
                (parsedCartographie === null || parseInt(item.mappingId) === parsedCartographie) &&
                (parsedBusinessUnit === null || parseInt(item.buId) === parsedBusinessUnit) &&
                (parsedCountry === null || parseInt(item.countryId) === parsedCountry) &&
                (parsedCompany === null || parseInt(item.companyId) === parsedCompany) &&
                (parsedBusinessActivity === null || parseInt(item.baId) === parsedBusinessActivity) &&
                (parseSearchKeyword === null || item.enTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase())) &&
                (parseSearchKeyword === null || item.frTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase()))
            );

            let assessedRisks = [];

            await Promise.all(risks.map(async risk => {
                assessedRisks.push({ ...risk, calcs: await getAssessment(risk.id) });
            }));

            // Remove the none evaluated ones
            let nonEvaluatedRisks = assessedRisks.filter(item => isEmpty(item.calcs));

            let evaluatedRisks = assessedRisks.filter(item => !isEmpty(item.calcs));

            // Sort the array by recently created
            evaluatedRisks = evaluatedRisks.sort((a, b) => {
                return b.calcs.riskBrute - a.calcs.riskBrute;
            });

            assessedRisks = [...evaluatedRisks, ...nonEvaluatedRisks];

            // Filter by the risk rank
            if( rankFilter !== null && rankFilter !== "not_evaluated" )
            {
                const filtered = [];

                evaluatedRisks.map(item => {

                    if( Number(rankFilter) === 1 )
                    {
                        if( Number(item.calcs.riskBrute) > 360 )
                        {
                            filtered.push(item);
                        }
                    }
                    else if( Number(rankFilter) === 2 )
                    {
                        if( Number(item.calcs.riskBrute) <= 360 && Number(item.calcs.riskBrute) > 160 )
                        {
                            filtered.push(item);
                        }
                    }
                    else if( Number(rankFilter) === 3 )
                    {
                        if( Number(item.calcs.riskBrute) <= 160 )
                        {
                            filtered.push(item);
                        }
                    }

                });

                setRiskList(filtered);
            }
            else if( rankFilter === null )
            {
                setRiskList(assessedRisks);
            }
            else if( rankFilter === "not_evaluated" )
            {
                setRiskList(nonEvaluatedRisks);
            }
        }
        else
        {
            setRiskList([]);
        }
    }

    async function getAssessment(riskId) {

        let assessments = await SendGet(`api/v1/assessments/risk/${riskId}`);

        if( !isEmpty(assessments) )
        {
            return assessments.data.reduce((max, obj) => (parseInt(obj.version) > parseInt(max.version) ? obj : max), assessments.data[0]);
        }
        else
        {
            return null;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getRiskList();
            } catch (error) {
                console.error(error);
            }
        };

        fetchData().catch(e => console.log(`Error occurred: `, e));

    }, [cartographie, businessUnit, country, company, businessActivity, searchKeyword, rankFilter]);

    return (
        <div style={styles.container}>
            <RiskTopMenu />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <RiskSideMenu showSideMenu={showSideMenu} selectedTab="grid" />
                <div>
                    <div style={{ marginLeft: 48 }}>
                        <NotificationBadge />
                    </div>
                    <div style={styles.bodyContainer}>
                        {!isEmpty(riskList) && <Paginator items={riskList} itemsPerPage={15} />}
                    </div>
                    <CircleMenu />
                </div>
            </div>

        </div>
    );
}
