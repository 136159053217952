import RiskSideMenu from "../Components/RiskSideMenu";
import {Link, useNavigate} from "react-router-dom";
import {useState, useEffect} from "react";
import {GetUser, SendDelete, SendGet} from "../State/Helper";
import RiskTopMenu from "../Components/RiskTopMenu";
import { primaryColor } from "../configuration/colorPalet";

export default function Mappings() {

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#FFFFFF'
        },
        mainSectionContainer: {
            marginLeft: 20,
            margin: 20,
            flex: 1,
            display: 'flex',
            flexDirection: 'column'
        },
        mainTitle: {
            fontSize: 34,
            color: primaryColor,
            fontWeight: 400,
            marginLeft: 20
        },
        mainButton: {
            color: '#000000',
            backgroundColor: '#FFFFFF',
            fontSize: 12,
            textTransform: 'uppercase',
            borderRadius: 5,
            padding: 4,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none',
            border: 'solid 1px #000000'
        },
        mainButtonIcon: {
            color: '#000000',
            verticalAlign: 'middle',
            fontSize: 20,
            fontWeight: 700
        },
        cardContainer: {
            display: 'flex',
            flexDirection: 'row',
            border: 'solid 1px #E2E2E2',
            padding: 20,
            marginBottom: 20,
            borderRadius: 5
        },
        mainContainer: {
            marginTop: 20
        },
        flagIcon: {
            width: 50,
            height: 30,
            verticalAlign: 'middle',
            marginRight: 10
        },
        businessUnitLabel: {
            color: primaryColor,
            fontWeight: 600
        },
        actionIcon: {
            fontSize: 22,
            fontWeight: 600,
            verticalAlign: 'middle'
        },
        actionIconContainer: {
            padding: 8,
            marginRight: 10,
        }
    }
    const navigate = useNavigate();
    const [mappings, setMappings] = useState([]);

    const getMappings = async () => {
        try {
            const response = await SendGet(`api/v1/mappings/all`);
            if (response.data !== undefined && Array.isArray(response.data)) {

                let data = await Promise.all(response.data.map(async (item) => {
                    let user = await GetUser(item.creatorId);
                    return { ...item, user: user };
                }));

                setMappings(data);
            }
        } catch (Exception) {
            console.log(Exception);
        }
    };

    const deleteMapping = async (id) =>  {
        if(window.confirm("Confirmez-vous la suppression ?"))
        {
            try
            {
                await SendDelete(`api/v1/mappings/${id}`);
                await getMappings();
            }
            catch(error)
            {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        getMappings();
    }, []);


    return (
        <div>
            <RiskTopMenu />
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div>
                        <span onClick={() => navigate("/mapping/create")} style={styles.mainButton} className="btn-primary-custom" >
                            <span className="material-symbols-outlined" style={styles.mainButtonIcon}>add</span>
                        </span>
                    </div>
                    <div style={styles.mainContainer}>
                        {
                            mappings && mappings.map(item => {
                                return (
                                    <div key={item.id} style={styles.cardContainer}>
                                        <div style={{ flex: 1 }}>
                                            <span style={styles.businessUnitLabel}>{item.name}</span>
                                            <span style={{ marginLeft: 15, fontSize: 12, fontWeight: 500, color: '#000000', border: `solid 1px #767676`, padding: 5, borderRadius: 5 }}><span className="material-symbols-outlined" style={{ fontSize: 16, verticalAlign: 'middle' }}>manage_accounts</span> {`${item.user.firstName} ${item.user.lastName}`}</span>
                                        </div>
                                        <div style={{ float: 'right' }}>
                                            <Link to={`/mapping/edit/${item.id}`} style={{ ...styles.actionIconContainer, borderColor: '#094392', marginTop: 20 }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: '#094392' }}>edit</span></Link>
                                            <span onClick={() => deleteMapping(item.id)} style={{ ...styles.actionIconContainer, cursor: 'pointer', borderColor: 'red', marginTop: 20 }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: 'red' }}>delete</span></span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
